<div class="mx-auto my-16 max-w-[1200px]">
  <div class="flex justify-center">
    <svg
      class="w-12 h-12 mr-3"
      fill="#fff"
      viewBox="0 -5 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m2.4 24h7.2l4.8-9.6v-14.4h-14.4v14.4h7.2zm19.2 0h7.2l4.8-9.6v-14.4h-14.4v14.4h7.2z"
      ></path>
    </svg>

    <blockquote
      class="pl-4 text-2xl font-light leading-8 text-left border-l-4 text-base-200"
    >
      To acheive your dreams you must apply discipline, but more importantly
      <span class="font-bold text-secondary">consistency</span>. Without
      commitment you'll never start, but without consistency you'll never
      finish.
    </blockquote>
  </div>
  <div class="flex justify-end">
    <h4 class="text-lg font-bold text-right text-base-200">
      Denzel Washington
    </h4>
  </div>
  <div class="flex justify-end">
    <span class="text-base font-light text-base-300"
      >Iconic American actor</span
    >
  </div>
</div>
