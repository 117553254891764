import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { maxDailyUsage } from '@goalmate/typings';

@Component({
  selector: 'goalmate-daily-usage',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="block w-full h-8 bottom-0 relative border border-base-300 card rounded-lg overflow-hidden"
    >
      <!-- Background fill -->
      <div
        class="absolute left-0 top-0 bg-primary h-full transition-all duration-300"
        [style.width.%]="(dailyUsage / maxUsage) * 100"
      ></div>
      <!-- Foreground text -->
      <div
        class="absolute left-0 top-0 h-full w-full flex justify-center items-center"
      >
        <p
          class="text-center transition-all"
          [class.text-primary-content]="dailyUsage / maxUsage > 0.5"
        >
          {{ dailyUsage }} / {{ maxUsage }} Messages
        </p>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyUsageComponent {
  @Input() dailyUsage = 0;
  maxUsage = maxDailyUsage;
}
