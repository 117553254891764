<div class="h-full max-w-3xl mx-auto">
  <div *ngIf="isLoading" class="text-center">
    <span class="loading loading-dots loading-lg"></span>
  </div>
  @for (message of messages(); track message.id) {
  <goalmate-chat-message
    [message]="message"
    [user]="user"
    [isPlaying]="message.id === playingMessageId"
    [isMobile]="isMobile"
    (playVoice)="onPlayVoice($event)"
    (pauseVoice)="onPauseVoice()"
  />
  }
</div>

<ng-template #noMessages>
  <!-- Container  -->
  <ng-container *ngIf="program">
    <div class="flex items-center justify-center h-full">
      <ng-container [ngSwitch]="isLoading">
        <!-- No messages message -->

        <p
          *ngSwitchCase="false"
          class="flex items-end justify-center gap-1 pt-5 text-center"
        >
          GoalMate is typing
          <span class="loading loading-dots loading-xs"></span>
        </p>
        <!-- Messages loader -->
        <span
          *ngSwitchCase="true"
          class="loading loading-dots loading-lg"
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
