import { NgTemplateOutlet } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SwiperDirective } from '@goalmate/directives';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'goalmate-main-features',
  templateUrl: './main-features.component.html',
  styleUrls: ['./main-features.component.scss'],
  standalone: true,
  imports: [SwiperDirective, NgTemplateOutlet],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainFeaturesComponent {
  swiperOpt: SwiperOptions = {
    modules: [Pagination, Navigation],
    navigation: {
      enabled: true,
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: false,
    },
    spaceBetween: 20,
    slidesPerView: 4.3,
    autoHeight: true,
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      600: {
        slidesPerView: 2.2,
        spaceBetween: 10,
      },
      800: {
        slidesPerView: 2.8,
        spaceBetween: 10,
      },
      1000: {
        slidesPerView: 3.5,
      },
      1200: {
        slidesPerView: 4.5,
      },
      1700: {
        slidesPerView: 5,
      },
    },
  };
}
