import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  OnInit,
} from '@angular/core';
import { dialog } from '@goalmate/presentational';
import { WebPlayerService } from '../../services/web-player.service';

export interface ModalMentorBioComponentData {
  bio: string;
  name: string;
  audio: string;
}

@Component({
  selector: 'goalmate-modal-mentor-bio',
  standalone: true,
  imports: [AsyncPipe, JsonPipe],
  templateUrl: './modal-mentor-bio.component.html',
  styleUrl: './modal-mentor-bio.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class ModalMentorBioComponent implements OnInit {
  protected data = inject<ModalMentorBioComponentData>(DIALOG_DATA);
  private webPlayer = inject(WebPlayerService);
  private dialogRef = inject(DialogRef);

  isPlaying = this.webPlayer.isPlaying;

  @HostBinding('@dialogAnimation')
  dialogAnimation = true;

  ngOnInit(): void {
    if (!this.webPlayer.isPlaying()) {
      this.webPlayer.preloadMetadata(this.data.audio);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onPlay() {
    this.webPlayer.play();
  }

  onStop() {
    this.webPlayer.pause();
  }
}
