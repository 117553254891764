import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { MobileService } from '@goalmate/services';

@Component({
  selector: 'goalmate-body-feature-wrapper',
  templateUrl: './body-feature-wrapper.component.html',
  styleUrls: ['./body-feature-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BodyFeatureWrapperComponent {
  private mobileService = inject(MobileService);

  @Input()
  reverse = false;

  @HostBinding('style.backgroundColor')
  @Input()
  bg = 'oklch(var(--b1))'; // base-100

  isMobile = this.mobileService.isMobile;
}
