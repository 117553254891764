import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import {
  ContentMessage,
  Message,
  MessageAuthor,
  User,
} from '@goalmate/typings';
import { AvatarComponent } from '../avatar/avatar.component';
import { AvatarUrlPipe } from './avatar.pipe';

@Component({
  selector: 'goalmate-chat-message',
  standalone: true,
  imports: [NgClass, DatePipe, NgIf, AvatarComponent, AvatarUrlPipe],
  template: `
    <button
      *ngIf="message.voiceUrl && !isPlaying"
      (click)="onPlay()"
      class="flex gap-1 ml-12 mt-1"
      [ngClass]="{
        'ml-4': isMobile
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
        />
      </svg>

      Play
    </button>
    <!-- Pause -->
    <button
      *ngIf="message.voiceUrl && isPlaying"
      (click)="onPause()"
      class="flex gap-1 ml-12 mt-1"
      [ngClass]="{
        'ml-4': isMobile
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      Pause
    </button>
    <!-- Chat bubble -->
    <div
      class="chat"
      [class.chat-start]="message.author !== author.ME"
      [class.chat-end]="message.author === author.ME"
    >
      <div *ngIf="!isMobile" class="chat-image avatar">
        <div class="w-10 rounded-full">
          @if(message.author === author.ME){
          <goalmate-avatar [user]="user" />
          } @else {
          <img [src]="message.author | avatar" />
          }
        </div>
      </div>
      <div
        class="whitespace-pre-line chat-bubble"
        [ngClass]="{
          'chat-bubble-primary': message.author === author.ME,
          'chat-bubble-accent': message.author !== author.ME,
          'text-lg': isMobile
        }"
      >
        {{ message.message }}
      </div>
      <div class="opacity-50 chat-footer">
        <time class="text-xs">{{ message.createdAt | date : 'short' }}</time>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  public el = inject(ElementRef<HTMLElement>);

  @Input() user!: User;
  @Input() message!: Message | ContentMessage;
  @Input() isPlaying = false;
  @Input() isMobile = false;

  @Output() playVoice = new EventEmitter<string>();
  @Output() pauseVoice = new EventEmitter<void>();

  author = MessageAuthor;

  get messageId() {
    return this.message?.id || undefined;
  }

  get element() {
    return this.el?.nativeElement;
  }

  scrollIntoView(opt: ScrollIntoViewOptions | boolean) {
    this.el?.nativeElement?.scrollIntoView(opt);
  }

  onPlay() {
    this.playVoice.emit(this.message.id);
  }

  onPause() {
    this.pauseVoice.emit();
  }
}
