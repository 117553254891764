import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'goalmate-theme-demo',
  standalone: true,
  template: `
    <div class="w-2 h-2 bg-primary rounded-full"></div>
    <div class="w-2 h-2 bg-secondary rounded-full"></div>
    <div class="w-2 h-2 bg-accent rounded-full"></div>
  `,
  styles: [
    `
      :host {
        @apply flex gap-1 items-center w-fit bg-base-100 p-2 rounded-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeDemoComponent {
  @HostBinding('attr.data-theme')
  @Input({ required: true })
  theme!: string;
}
