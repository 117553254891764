import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { MainFeaturesComponent } from './components/main-features/main-features.component';
import { FeaturedOnComponent } from './components/featured-on/featured-on.component';
import { SecondSectionComponent } from './components/second-section/second-section.component';
import { FirstScreenComponent } from './components/first-screen/first-screen.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { register } from 'swiper/element';
import { DescriptionsComponent } from './components/descriptions/descriptions.component';
import { ProgramComponent } from './components/program/program.component';
import { MentorsComponent } from './components/mentors/mentors.component';
import { CommunityComponent } from './components/community/community.component';

@Component({
  selector: 'goalmate-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NavbarComponent,
    FirstScreenComponent,
    SecondSectionComponent,
    FeaturedOnComponent,
    MainFeaturesComponent,
    WaitListComponent,
    FooterComponent,
    DescriptionsComponent,
    ProgramComponent,
    MentorsComponent,
    CommunityComponent,
  ],
})
export class AppComponent {
  constructor() {
    register();
  }
}
