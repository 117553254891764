import { NgModule } from '@angular/core';
import { MenuItemComponent } from './menu-item.component';
import { MenuComponent } from './menu.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { GoalmateMenuItemDirective } from './menu-item.directive';

@NgModule({
  imports: [
    MenuComponent,
    MenuItemComponent,
    OverlayModule,
    GoalmateMenuItemDirective,
  ],
  exports: [
    MenuComponent,
    MenuItemComponent,
    OverlayModule,
    GoalmateMenuItemDirective,
  ],
})
export class MenuModule {}
