import { Injectable, inject } from '@angular/core';
import { PostsRepositoryService } from '@goalmate/repository';
import { Goal, PostDto } from '@goalmate/typings';
import {
  Storage,
  deleteObject,
  getDownloadURL,
  ref,
  uploadString,
} from '@angular/fire/storage';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  private storage = inject(Storage);
  private postsDB = inject(PostsRepositoryService);

  async createPost(userId: string, image: string, content: string) {
    const postId = this.postsDB.generateId();
    const imgUrl = await this.uploadImage(userId, image, postId);
    const postDto: PostDto = {
      id: postId,
      imgs: [imgUrl],
      content,
      userId,
      likesCount: 0,
      commentsCount: 0,
    };
    // Create post in database
    const post = await this.postsDB.create(postDto);
    return post;
  }

  async deletePost(userId: string, postId: string) {
    // Delete post in database
    await this.postsDB.delete(postId as string);
    // Delete image in storage
    await this.deleteImage(`users/${userId}/posts/${postId}.png`);
  }

  makeContent(goal: Goal) {
    const completedDate = dayjs(goal.updatedAt).format('L');
    const content = `I completed goal "${goal.goal}" on ${completedDate}. #GoalMate #GoalCompleted`;
    return content;
  }

  private async uploadImage(userId: string, image: string, postId: string) {
    const storageRef = ref(this.storage, `users/${userId}/posts/${postId}.png`);
    await uploadString(storageRef, image, 'base64');
    return getDownloadURL(storageRef);
  }

  private async deleteImage(imagePath: string) {
    const storageRef = ref(this.storage, imagePath);
    await deleteObject(storageRef);
  }
}
