import { Pipe, PipeTransform, inject } from '@angular/core';
import { MentorsService } from '@goalmate/services';
import { MessageAuthor } from '@goalmate/typings';

@Pipe({ name: 'avatar', standalone: true })
export class AvatarUrlPipe implements PipeTransform {
  private mentorsService = inject(MentorsService);
  private readonly goalMateAvatar = 'assets/images/goalmate-chat.png';

  mentors = this.mentorsService.getMentors();

  transform(author: MessageAuthor | string): string {
    if (author === MessageAuthor.GOALMATE) {
      return this.goalMateAvatar;
    }

    const mentor = this.mentors.find((m) => m.id === author);

    return mentor?.avatarUrl || this.goalMateAvatar;
  }
}
