<!-- Swiper slider -->
<swiper-container #swiperRef goalmateSwiper [config]="swiperOpt" init="false">
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_1"></ng-container>
  </swiper-slide>
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_2"></ng-container>
  </swiper-slide>
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_3"></ng-container>
  </swiper-slide>
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_4"></ng-container>
  </swiper-slide>
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_5"></ng-container>
  </swiper-slide>
  <swiper-slide>
    <ng-container *ngTemplateOutlet="slide_6"></ng-container>
  </swiper-slide>
</swiper-container>

<!-- Swiper Navigation buttons -->
<div class="invisible swiper-button-prev xl:visible"></div>
<div class="invisible swiper-button-next xl:visible"></div>

<!-- Swiper Pagination -->
<div class="swiper-pagination"></div>

<!-- Slide templates -->
<ng-template #slide_1>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        Simple Goal Setting
      </h2>
    </div>
  </div>
</ng-template>

<ng-template #slide_2>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        Daily Progress Tracking
      </h2>
    </div>
  </div>
</ng-template>

<ng-template #slide_3>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        AI Mentorship Support
      </h2>
    </div>
  </div>
</ng-template>

<ng-template #slide_4>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        Lifestyle Improvement
      </h2>
    </div>
  </div>
</ng-template>

<ng-template #slide_5>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        Life Balance & Organic Growth
      </h2>
    </div>
  </div>
</ng-template>

<ng-template #slide_6>
  <div class="flex flex-none h-full shadow-md card bg-base-200">
    <div class="card-body">
      <div
        class="flex items-center justify-center w-10 h-10 mx-auto rounded-full shadow bg-base-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-info"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
          />
        </svg>
      </div>

      <h2 class="text-2xl text-center card-title text-base-500">
        GoalMate Community
      </h2>
    </div>
  </div>
</ng-template>
