@for(desc of descriptions; track $index) {
<goalmate-body-feature-wrapper [bg]="desc.bg" [reverse]="desc.isReversed">
  <!-- Title -->
  <span feature-title class="text-4xl font-bold">{{ desc.title }}</span>

  <!-- Description -->
  <p feature-description class="text-2xl mt-9">
    {{ desc.description }}
  </p>

  <!-- Image -->
  <img
    feature-image
    [src]="desc.imgSrc"
    [alt]="desc.imgAlt"
    class="max-w-[480px] h-full w-full"
  />
</goalmate-body-feature-wrapper>
}
