import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InputSignal,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { PostCommunity } from '@goalmate/typings';
import { PostCommentComponent } from '../post-comment/post-comment.component';
import { MobileService, ModerationService } from '@goalmate/services';
import { finalize } from 'rxjs';

export interface PostCommentsData {
  post: InputSignal<PostCommunity>;
}

@Component({
  selector: 'goalmate-post-comments',
  standalone: true,
  imports: [CdkTextareaAutosize, ReactiveFormsModule, PostCommentComponent],
  templateUrl: './post-comments.component.html',
  styleUrl: './post-comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentsComponent {
  private fb = inject(FormBuilder);
  private data = inject<PostCommentsData>(DIALOG_DATA);
  private dialogRef = inject(DialogRef);
  private mobileService = inject(MobileService);
  private moderationService = inject(ModerationService);

  inapropriateContent = signal(false);
  isLoading = signal(false);

  post = this.data.post;

  newComment = output<string>();

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  form = this.fb.nonNullable.group({
    message: ['', [Validators.required]],
  });

  onKeydown(event: Event) {
    const isMobile = this.mobileService.isMobile();
    const keyEv = event as KeyboardEvent;
    if (keyEv.key === 'Enter' && !keyEv.shiftKey && !isMobile) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (!this.form.valid || !this.form.controls.message.value.trim()) {
      this.form.markAllAsTouched();
      return;
    }
    const { message } = this.form.value;

    this.isLoading.set(true);
    this.moderationService
      .moderate(message as string)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe((passed) => {
        // Validate content
        if (!passed) {
          this.inapropriateContent.set(true);
          return;
        }
        // Add comment
        this.newComment.emit((message || '').trim());
        this.form.reset();
        this.autosize.reset();
      });
  }
}
