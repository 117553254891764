import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Program } from '@goalmate/typings';

@Component({
  selector: 'goalmate-program-progress',
  standalone: true,
  template: `<span>Day {{ program.currentDay }}</span>
    <div class="flex items-center gap-3 w-full">
      <span>1</span>
      <progress
        class="progress w-full progress-accent"
        [value]="program.currentDay"
        [max]="program.totalDays"
      ></progress>
      <span>{{ program.totalDays }}</span>
    </div>`,
  styles: [
    `
      :host {
        @apply flex flex-col items-center gap-1 w-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramProgressComponent {
  @Input() program!: Program;
}
