<div class="container mx-auto text-center">
  <h2 class="mb-10 text-3xl font-light lg:text-5xl text-base-100">
    Get early access. Sign up for waitlist
  </h2>
  <!-- Signup form -->
  <form
    [formGroup]="waitlistForm"
    (ngSubmit)="onEmailSubmit()"
    class="flex gap-4 mx-auto w-fit"
  >
    <div class="form-control">
      <input
        type="email"
        id="email"
        placeholder="Email"
        formControlName="email"
        class="h-12 input input-bordered w-[325px]"
        [class.input-error]="
          waitlistForm.controls.email.touched && waitlistForm.invalid
        "
        [ngClass]="{
          'input-error':
            waitlistForm.controls.email.touched && waitlistForm.invalid
        }"
      />
      <label class="label">
        <span
          class="text-sm label-text-alt text-base-200"
          *ngIf="waitlistForm.valid || !waitlistForm.controls.email.touched"
          >Your privacy 100% secured. We do not send spam.</span
        >
        <span
          *ngIf="
            waitlistForm.controls.email.hasError('email') &&
            waitlistForm.controls.email.touched
          "
          class="text-sm label-text-alt text-error"
          >Please, enter valid email!</span
        >
        <span
          *ngIf="
            waitlistForm.controls.email.hasError('required') &&
            waitlistForm.controls.email.touched
          "
          class="text-sm label-text-alt text-error"
          >Please, enter email!</span
        >
      </label>
    </div>
    <!-- Button -->
    <button type="submit" class="w-[155px] font-normal btn btn-neutral">
      Sign up
    </button>
  </form>
</div>

<!-- Modal popup window -->
<dialog #modal class="modal">
  <form method="dialog" class="w-10/12 max-w-5xl modal-box">
    <!-- Success message -->
    <h3 *ngIf="!waitlistSuccess" class="text-3xl font-bold">
      Something went wrong...
    </h3>
    <p *ngIf="!waitlistSuccess" class="py-5 text-xl font-light">
      Please, try again later.
    </p>
    <!-- Failure message -->
    <h3 *ngIf="waitlistSuccess" class="text-3xl font-bold">
      Thank you for joining the waitlist!
    </h3>
    <p *ngIf="waitlistSuccess" class="py-5 text-xl font-light">
      You will be receiving priority access to GoalMate.
    </p>
    <div class="modal-action">
      <button class="font-normal btn btn-neutral">Close</button>
    </div>
  </form>
</dialog>
