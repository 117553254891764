import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

interface Program {
  chapter: string;
  sections: string[];
}

@Component({
  selector: 'goalmate-program',
  standalone: true,
  imports: [],
  templateUrl: './program.component.html',
  styleUrl: './program.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramComponent {
  isLoaded = signal<boolean>(false);

  readonly program: Program[] = [
    {
      chapter: 'Days 1-7: Introduction to GoalMate',
      sections: [
        'Goalmate Principles',
        'Essence of Daily Reports',
        '90 Days Program Duration',
        'GoalMate Timer',
        'Goals Categorization',
        'Starting with Simple Goals',
        'Transactional Goals vs Infinite Goals',
      ],
    },
    {
      chapter: 'Days 8-15: Adopting Useful Habits and Elevating Lifestyle',
      sections: [
        'Importance of Physical Activities',
        'Digital Detox',
        'Organizing Your Environment',
        'Healthy Eating Habits',
        'Rest and Recovery',
        'Taking Care of Yourself',
        'Completing Unfinished Tasks',
        'Overcoming Emotional Burnout',
      ],
    },
    {
      chapter: 'Days 16-24: Starting Point',
      sections: [
        'Understanding Your Starting Point',
        'Taking 100% Responsibility',
        'Decide What You Want',
        'Breaking Goals Into Smaller Tasks',
        'Goals Prioritization',
        'Re-adjusting Abandoned Goals',
        'Building Habits',
        'Adopting Healthy Habits',
        'Eliminating Bad Habits',
      ],
    },
  ];

  onLoadMore() {
    const program = [
      {
        chapter: 'Days 25-34: Dealing with Setbacks and Rejections',
        sections: [
          'Obstacles and Failures',
          'Overcoming Obstacles',
          'Believing in Yourself',
          'Dealing With Fear',
          'Eliminate Imaginary Fears',
          'Not Everything Depends on You',
          'Use Chances That You Are Given',
          'Acting “As If”',
          'Take Action',
          'Do Not Give Up Too Soon',
        ],
      },
      {
        chapter: 'Days 35-47: Techniques for Setting and Achieving Goals',
        sections: [
          'Introduction to SMART Goals',
          'Goal-Setting Techniques',
          'Setting Deadlines',
          '10,000-hour Rule',
          'Power of Concentration',
          'Raise Your Bar',
          'Surround Yourself With Like-Minded People',
          'How to Use Willpower',
          'Focusing on Core Tasks',
          'Power of Asking',
          'Listen to the Feedback',
          'Hard Work Pays Off',
          'Never Ending Improvement',
        ],
      },
      {
        chapter: 'Days 48-54: Finance - Healthy Financial Habits and Routines',
        sections: [
          'Financial Health Basics',
          'Budgeting Techniques',
          'Saving Strategies',
          'Managing Debt',
          'Introduction to Investing',
          'Setting Financial Goals',
          'Building Multiple Income Streams',
        ],
      },
      {
        chapter: 'Days 55-61: Health - Taking Care of Yourself',
        sections: [
          'Regular Health Check-Ups',
          'Daily Exercise Routines',
          'Balanced Diet',
          'Mental Health Awareness',
          'Stress Management',
          'Building a Healthy Lifestyle',
          'Sleep Hygiene',
        ],
      },
      {
        chapter:
          'Days 62-68: Relationships - Building and Improving Relationships',
        sections: [
          'Value of Strong Relationships',
          'Effective Communication',
          'Building Trust',
          'Family Relationships',
          'Friendships',
          'Professional Networking',
          'Self-Relationship',
        ],
      },
      {
        chapter: 'Days 69-75: Joy - Embracing Life Fully',
        sections: [
          'Importance of Joy',
          'Taking Breaks and Vacations',
          'Exploring Hobbies',
          'Embracing Creativity',
          'Experiencing New Things',
          'Balancing Work and Play',
          'Living in the Moment',
        ],
      },
      {
        chapter: 'Days 76-83: Contribution to Society - Helping Others',
        sections: [
          'Value of Contribution',
          'Volunteering',
          'Everyday Acts of Kindness',
          'Creating a Positive Impact',
          'Building a Legacy',
          'Mentoring Others',
          'Art of Giving Back',
          'Environmental Impact',
        ],
      },
      {
        chapter: 'Days 84-90: Final Review and Future Planning',
        sections: [
          'Review Your Journey With GoalMate',
          'Celebrating Achievements',
          'Addressing Remaining Challenges',
          'Setting Future Goals',
          'Building a Long-Term Action Plan',
          'Support and Maintain Your Network',
          'Mentorship Graduation and Next Steps',
        ],
      },
    ];
    this.program.push(...program);
    this.isLoaded.set(true);
  }
}
