import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'goalmate-display-card',
  standalone: true,
  template: `<div class="w-full card card-bordered border-base-300">
    <h2 class="p-5 text-xl font-semibold">{{ cardTitle }}</h2>
    <hr />
    <div class="p-2 card-body"><ng-content></ng-content></div>
  </div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCardComponent {
  @Input() cardTitle = '';
}
