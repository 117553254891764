import { Pipe, PipeTransform } from '@angular/core';
import { GoalDirection } from '@goalmate/typings';

@Pipe({
  name: 'directionName',
  standalone: true,
})
export class DirectionNamePipe implements PipeTransform {
  transform(direction: GoalDirection | string): string {
    if (typeof direction === 'string') return direction;

    switch (direction) {
      case GoalDirection.FINANCE:
        return 'Finance';
      case GoalDirection.HEALTH:
        return 'Health';
      case GoalDirection.RELATIONSHIP:
        return 'Relationship';
      case GoalDirection.JOY:
        return 'Joy';
      case GoalDirection.OTHER:
        return 'Other';
    }
  }
}
