/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GoalDirection } from '@goalmate/typings';

@Injectable({
  providedIn: 'root',
})
export class GoalToImgService {
  private renderer: Renderer2;

  private width: number = 800;
  private height: number = 800;
  private maxTextWidth: number = 760;
  private maxTextLines: number = 5;
  private fontSize: number = 100;
  private fontFamily: string = 'GoalMate';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  async makeImg(goal: { goal: string; direction: GoalDirection }) {
    const text = goal.goal;
    const categoryImg = await this.loadCatImg(goal.direction);
    const canvas = this.makeCanvas();
    canvas.width = this.width;
    canvas.height = this.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.addBackground(ctx);
    this.addGoalText(ctx, text);
    this.addImage(ctx, categoryImg);
    this.addWebsite(ctx);
    return canvas.toDataURL();
  }

  private addGoalText(ctx: CanvasRenderingContext2D, text: string) {
    ctx.font = `${this.fontSize}px ${this.fontFamily}`;
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';

    let textLines = this.countLines(ctx, text, this.maxTextWidth);
    let fSize = this.fontSize;

    // Decrease font size until text fits
    while (textLines > this.maxTextLines) {
      fSize -= 2;
      ctx.font = `${fSize}px ${this.fontFamily}`;
      textLines = this.countLines(ctx, text, this.maxTextWidth);
    }

    const textHeight: number = fSize;
    const y = this.height / 2 - (textLines * textHeight) / 2;
    const x = this.width / 2;

    this.drawTextWithLineBreaks(ctx, text, x, y, this.maxTextWidth, fSize);
  }

  private drawTextWithLineBreaks(
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    fontSize: number,
  ): void {
    const words: string[] = text.split(' ');
    const textHeight: number = fontSize;
    let line: string = '';

    for (let i = 0; i < words.length; i++) {
      const testLine: string = line + words[i] + ' ';
      const metrics: TextMetrics = ctx.measureText(testLine);
      const testWidth: number = metrics.width;

      if (testWidth > maxWidth && i > 0) {
        ctx.fillText(line, x, y);
        line = words[i] + ' ';
        y += textHeight;
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, y);
  }

  private addBackground(ctx: CanvasRenderingContext2D) {
    // Define gradient parameters
    const gradient = ctx.createRadialGradient(
      this.width / 2,
      this.height / 2,
      1, // Inner circle position and radius
      this.width / 2,
      this.height / 2,
      Math.max(this.width, this.height), // Outer circle position and radius
    );

    // Add color stops
    gradient.addColorStop(0, '#FDFA95');
    gradient.addColorStop(1, '#CDC801');

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, this.width, this.height);
  }

  private addImage(
    ctx: CanvasRenderingContext2D,
    goalMateImg: HTMLImageElement,
  ) {
    ctx.drawImage(goalMateImg as HTMLImageElement, 730, 20, 50, 50);
  }

  private addWebsite(ctx: CanvasRenderingContext2D) {
    ctx.font = `30px ${this.fontFamily}`;
    ctx.fillStyle = 'black';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'bottom';
    ctx.fillText('#GoalMate', 20, 780);
  }

  private makeCanvas() {
    return this.renderer.createElement('canvas') as HTMLCanvasElement;
  }

  private async loadCatImg(direction: GoalDirection) {
    let scr: string = '';
    switch (direction) {
      case GoalDirection.FINANCE:
        scr = 'assets/images/cat-icon-finance.svg';
        break;
      case GoalDirection.HEALTH:
        scr = 'assets/images/cat-icon-health.svg';
        break;
      case GoalDirection.RELATIONSHIP:
        scr = 'assets/images/cat-icon-relationships.svg';
        break;
      case GoalDirection.JOY:
        scr = 'assets/images/cat-icon-joy.svg';
        break;
      case GoalDirection.OTHER:
        scr = 'assets/images/cat-icon-other.svg';
    }
    return new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.src = scr;
      img.onload = () => resolve(img);
    });
  }

  private countLines(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number,
  ): number {
    let linesCount = 1;
    let line = '';
    const words: string[] = text.split(' ');

    for (let i = 0; i < words.length; i++) {
      const testLine: string = line + words[i] + ' ';
      const metrics: TextMetrics = ctx.measureText(testLine);
      const testWidth: number = metrics.width;

      if (testWidth > maxWidth && i > 0) {
        linesCount++;
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
    return linesCount;
  }
}
