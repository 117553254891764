import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ContentMessage, Message, Program, User } from '@goalmate/typings';
import { ChatMessageComponent } from '../chat-message/chat-message.component';
import { DashboardSliderService } from '@goalmate/services';

@Component({
  selector: 'goalmate-chat-messages',
  standalone: true,
  imports: [NgSwitch, NgFor, NgIf, NgSwitchCase, ChatMessageComponent],
  templateUrl: './chat-messages.component.html',
  styles: [
    `
      :host {
        @apply block w-full h-full  overflow-auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesComponent implements OnDestroy {
  private elementRef = inject(ElementRef);
  private slider = inject(DashboardSliderService, { optional: true });

  @Input() user!: User;
  @Input() isLoading!: boolean | null;
  @Input() playingMessageId: string | null | undefined;
  @Input() program: Program | null | undefined;
  @Input() isMobile = false;
  @Output() playVoice = new EventEmitter<string>();
  @Output() pauseVoice = new EventEmitter<void>();
  readMessage = output<ContentMessage>();

  scrollDirection = input.required<'up' | 'down'>();
  isGoalmate = input.required<boolean>();
  messages = input.required<Message[] | ContentMessage[]>();

  @ViewChildren(ChatMessageComponent, { emitDistinctChangesOnly: true })
  chatMessages: QueryList<ChatMessageComponent> | undefined;

  @ViewChildren(ChatMessageComponent, { read: ElementRef })
  chatMessagesEls: QueryList<ElementRef<HTMLDivElement>> | undefined;

  /**
   * Cache for checked messages for read
   */
  checkedMessagesForRead = new Set<string>();

  @HostListener('scroll')
  onScroll() {
    // Check only if slider with messages is active or desktop
    if (!this.isMobile || this.slider?.selectedSlide() === 1) {
      this.checkVisibleMessages();
    }
  }

  private lastGoalId: string | undefined;
  private firstMsgId: string | undefined;

  constructor() {
    effect(() => this.handleMessagesChange(this.messages()));
    effect(() => {
      if (this.slider?.selectedSlide() === 1) {
        this.checkVisibleMessages();
      }
    });
  }

  onPlayVoice(messageId: string) {
    this.playVoice.emit(messageId);
  }

  onPauseVoice() {
    this.pauseVoice.emit();
  }

  scrollDown() {
    this.instantScrollToLastMessage();
  }

  private async handleMessagesChange(msgs: Message[] | ContentMessage[]) {
    // Make sure we have messages
    if (!msgs || msgs.length === 0) return;

    // Check if new goal was selected. Reset scroll position. Update lastGoalId and firstMsgId
    if (this.lastGoalId !== msgs[0].goalId) {
      this.instantScrollToLastMessage();
      this.lastGoalId = msgs[0].goalId;
      this.firstMsgId = msgs[0].id;
      return;
    } else if (this.scrollDirection() === 'down') {
      // New message was added
      this.scrollIntoView();
    } else if (this.scrollDirection() === 'up') {
      // Scrolled up
      this.scrollToMessage(this.firstMsgId as string);
    }

    this.lastGoalId = msgs[0].goalId;
    this.firstMsgId = msgs[0].id;
  }

  private scrollIntoView() {
    const lastMsg = this.chatMessages?.last;
    if (lastMsg) {
      this.smoothScrollTotMessage(lastMsg);
    }
  }

  private scrollToMessage(id: string) {
    const msg = this.chatMessages?.find((msg) => msg.messageId === id);
    if (msg?.element) {
      msg.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }

  private instantScrollToLastMessage() {
    const lastUnreadMsg = this.chatMessages?.find(
      (msg) => msg.message.read === false,
    );
    const lastMsgEl = lastUnreadMsg
      ? lastUnreadMsg.el
      : this.chatMessagesEls?.last;
    if (!lastMsgEl) return;
    const elTop = this.elementRef.nativeElement.offsetTop;
    const top = lastMsgEl.nativeElement.offsetTop;
    this.elementRef.nativeElement.scrollTo({ top: top - elTop });
  }

  private smoothScrollTotMessage(msg: ChatMessageComponent) {
    const element = msg.element;
    if (!element) return;
    const elTop = this.elementRef.nativeElement.offsetTop;
    const top = element.offsetTop;
    this.elementRef.nativeElement.scrollTo({
      top: top - elTop,
      behavior: 'smooth',
    });
  }

  private checkVisibleMessages(): void {
    const messages = this.chatMessages?.filter(
      (msg) =>
        !this.checkedMessagesForRead.has(msg.message.id) &&
        msg.message.read === false,
    );

    messages?.forEach((message) => {
      if (this.isElementInViewport(message.element)) {
        this.checkedMessagesForRead.add(message.message.id);
        this.readMessage.emit(message.message as ContentMessage);
      }
    });
  }

  private isElementInViewport(el: HTMLElement): boolean {
    const container = this.elementRef.nativeElement as HTMLElement;
    const containerRect = container.getBoundingClientRect();
    const rect = el.getBoundingClientRect();
    return rect.top < containerRect.bottom && rect.bottom > containerRect.top;
  }

  ngOnDestroy() {
    this.checkedMessagesForRead.clear();
  }
}
