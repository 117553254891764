<div
  class="w-full max-w-[400px] overflow-hidden shadow-lg card border border-base-300 bg-white text-black"
>
  <div class="max-h-[400px] h-full w-auto">
    <img
      [ngSrc]="post().imgs[0]"
      [priority]="true"
      height="400"
      width="400"
      alt="Post"
    />
  </div>
  <div class="px-4 py-4 card-body">
    <div class="flex items-center gap-3">
      <div class="avatar">
        <div class="w-8 rounded-full">
          <img [src]="post().user.avatar" width="32" height="32" />
        </div>
      </div>
      <p class="font-medium">{{ post().user.name }}</p>
    </div>
    <p class="text-start">
      {{ post().content }}
    </p>

    <div class="flex items-center">
      <!-- Social icons  -->
      <ng-container
        *ngTemplateOutlet="post().liked ? like : noLike"
      ></ng-container>
      <span class="ml-1">{{ post().likesCount }}</span>
      <button (click)="onOpenComments()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 ml-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
          />
        </svg>
      </button>
      <span class="ml-1">{{ post().commentsCount }}</span>
    </div>
  </div>
</div>

<ng-template #like>
  <button (click)="onUnlike()" class="w-6 h-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="size-6"
    >
      <path
        d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z"
      />
    </svg>
  </button>
</ng-template>
<ng-template #noLike>
  <button (click)="onLike()" class="w-6 h-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
      />
    </svg>
  </button>
</ng-template>
