import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { firstValueFrom } from 'rxjs';
import { convertHourStringTo24Hour } from '@goalmate/utils';

// use plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private http = inject(HttpClient);

  /**
   * Fetches a list of timezones from a JSON file and ensures the user's current timezone is included.
   * If the user's timezone is not in the list, it is added and the list is sorted before being returned.
   *
   * @returns {Promise<string[]>} A sorted list of timezones including the user's current timezone.
   */
  async getTimezones(): Promise<string[]> {
    const myTimezone = this.getMyTimezone();
    let timezones = await firstValueFrom(
      this.http.get<string[]>('assets/data/timezones.json'),
    );

    if (!timezones.includes(myTimezone)) {
      timezones.push(myTimezone);
      timezones = timezones.sort();
    }

    return timezones;
  }

  /**
   * Uses the dayjs library's timezone plugin to guess the user's current timezone.
   * The guess method uses the Internationalization API to determine the user's timezone if available,
   * otherwise it falls back to checking specific dates to guess the timezone.
   *
   * @returns {string} The guessed timezone.
   */
  getMyTimezone(): string {
    return dayjs.tz.guess();
  }

  /**
   * Calculates the next check date based on the provided due time and timezone.
   *
   * @param {string} dueTime - The due time in 24-hour format (e.g., '13:00' for 1 PM).
   * @param {string} timezone - The timezone in which to calculate the next check date.
   * @returns {Date} The next check date in the provided timezone.
   */
  getNextCheckDate(dueTime: string, timezone: string): Date {
    const hours = convertHourStringTo24Hour(dueTime);
    const nextCheck = dayjs
      .tz(new Date(), timezone)
      .add(1, 'day')
      .startOf('day')
      .add(hours, 'hours');
    return nextCheck.toDate();
  }

  /**
   * Calculates the next check date based on the provided due time, in local time.
   *
   * This function is using browser time to display next check in correct local time.
   *
   * @param {string} dueTime - The due time in 24-hour format (e.g., '13:00' for 1 PM).
   * @returns {string} The next check date in local time format.
   */
  getNextCheckLocalDate(dueTime: string): string {
    const hours = convertHourStringTo24Hour(dueTime);
    const nextCheck = dayjs().add(1, 'day').startOf('day').add(hours, 'hours');
    return nextCheck.local().format('LLLL');
  }
}
