import { GoalDirection } from '@goalmate/typings';

export function convertHourStringTo24Hour(hourString: string) {
  const [hour, ampm] = hourString.split(' ');
  let hourValue = parseInt(hour);

  if (ampm === 'PM' && hourValue !== 12) {
    hourValue += 12;
  } else if (ampm === 'AM' && hourValue === 12) {
    hourValue = 0;
  }

  return hourValue;
}

export function transformDirectionsToEnum(val: string | GoalDirection) {
  if (typeof val === 'string') {
    switch (val) {
      case 'finance':
        return GoalDirection.FINANCE;
      case 'health':
        return GoalDirection.HEALTH;
      case 'joy':
        return GoalDirection.JOY;
      case 'relationship':
        return GoalDirection.RELATIONSHIP;
      case 'other':
        return GoalDirection.OTHER;
    }
  }
  return val;
}

export function transformDirectionsToString(val: GoalDirection) {
  switch (val) {
    case GoalDirection.FINANCE:
      return 'finance';
    case GoalDirection.HEALTH:
      return 'health';
    case GoalDirection.RELATIONSHIP:
      return 'relationship';
    case GoalDirection.JOY:
      return 'joy';
    case GoalDirection.OTHER:
      return 'other';
  }
}
