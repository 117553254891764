import { KeyValuePipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MentorSkills } from '@goalmate/typings';

@Component({
  selector: 'goalmate-mentors-skills',
  standalone: true,
  imports: [KeyValuePipe, TitleCasePipe],
  template: `@for(skill of skills() | keyvalue ; track $index) {
    <p class="mb-1 text-lg font-normal sm:text-xl">
      {{ skill.key | titlecase }}
    </p>
    <progress
      class="mb-1 progress progress-accent"
      [value]="skill.value"
      max="10"
    ></progress>
    }`,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorsSkillsComponent {
  skills = input<MentorSkills>();
}
