import { Injectable, computed, inject, signal } from '@angular/core';
import { GoalsActions, goalsSelectors } from '@goalmate/state';
import { Message, MessageAuthor } from '@goalmate/typings';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class GoalmateTypingService {
  private store = inject(Store);

  private goalId = signal<string | null>(null);
  private holdingMessages = new Map<string, Message>();

  typingGoalId = this.goalId.asReadonly();
  isTyping = computed(() => !!this.goalId());

  startTyping(goalId: string) {
    this.goalId.set(goalId);
  }

  stopTyping(message: Message) {
    this.goalId.set(null);
    this.releaseMessage(message);
  }

  /**
   * Add a new message to the store with typing effect
   * @param message Message to add
   */
  addMessage(message: Message) {
    if (message.author === MessageAuthor.ME) {
      // Check if holing message is for the same goal.
      // If so, release the messages first
      this.releaseHoldingMessagesByGoalId(message.goalId);
      this.addMessageToStore(message);
      return;
    }

    // Hold the message until the typing is done
    this.holdingMessages.set(message.id, message);

    // If the message is not for the current typing goal, release it after 3 seconds
    const typingGoalId = this.goalId();
    if (typingGoalId !== message.goalId) {
      this.releaseMessageAfter(message, 3000);
      return;
    }

    // Add fallback to release the message after 10 seconds regardless of the typing goal
    this.releaseMessageAfter(message, 10000);
  }

  releaseMessage(message: Message) {
    const storeMessage = this.holdingMessages.get(message.id);
    if (!storeMessage) return;
    this.addMessageToStore(message);
    this.clearMessage(message);
  }

  releaseMessageAfter(message: Message, ms = 3000) {
    setTimeout(() => {
      const storeMessage = this.holdingMessages.get(message?.id);
      if (!storeMessage) return;
      this.addMessageToStore(storeMessage);
      this.clearMessage(storeMessage);
    }, ms);
  }

  clear() {
    this.goalId.set(null);
    this.holdingMessages.clear();
  }

  private addMessageToStore(message: Message) {
    const goalId = message.goalId;
    const messages =
      this.store.selectSignal(
        goalsSelectors.selectedGoalMessagesById({ goalId }),
      )() || [];
    const sortedMessages = [...messages, message].sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
    );
    this.store.dispatch(
      GoalsActions.updateGoal({
        update: { id: goalId, changes: { messages: sortedMessages } },
      }),
    );
  }

  private clearMessage(message: Message) {
    const typingGoalId = this.goalId();
    if (typingGoalId === message.goalId) {
      this.goalId.set(null);
    }
    this.holdingMessages.delete(message.id);
  }

  private releaseHoldingMessagesByGoalId(goalId: string) {
    const messages = Array.from(this.holdingMessages.values());
    const messagesToRelease = messages.filter((m) => m.goalId === goalId);
    messagesToRelease.forEach((m) => this.releaseMessage(m));
  }
}
