import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { dialog } from '../dialog-animation';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { MentorsService, ModerationService } from '@goalmate/services';
import { GoalDirection } from '@goalmate/typings';
import { RouterModule } from '@angular/router';
import { finalize } from 'rxjs';

export interface CreateNewGoalComponentRes {
  direction: GoalDirection;
  mentorId: string;
  goal: string;
  goalType?: 'active' | 'future';
}

export interface CreateNewGoalComponentData {
  activeProgram: boolean;
}

@Component({
  selector: 'goalmate-create-new-goal',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, RouterModule],
  templateUrl: './create-new-goal.component.html',
  styleUrl: './create-new-goal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class CreateNewGoalComponent implements OnInit {
  private dialorRef = inject(DialogRef);
  readonly data = inject<CreateNewGoalComponentData>(DIALOG_DATA);
  private fb = inject(FormBuilder);
  private mentorsService = inject(MentorsService);
  private moderationService = inject(ModerationService);

  form = this.makeForm();

  mentors = this.mentorsService.getMentors();

  inapropriateContent = signal(false);
  isLoading = signal(false);

  ngOnInit(): void {
    if (!this.data.activeProgram) {
      (this.form as any).removeControl('goalType');
    }
  }

  /**
   * Closes the dialog and returns a result of false.
   */
  onCancel() {
    this.dialorRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading.set(true);
    const res = this.form.value;
    this.moderationService
      .moderate(res.goal as string)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe((passed) => {
        if (!passed) {
          this.inapropriateContent.set(true);
          return;
        }
        const data: CreateNewGoalComponentRes = {
          direction: +(res.direction as GoalDirection),
          mentorId: res.mentorId as string,
          goal: res.goal as string,
          goalType: res.goalType as any,
        };
        this.dialorRef.close(data);
      });
  }

  private makeForm() {
    return this.fb.nonNullable.group({
      direction: [0, [Validators.required, this.validateDirection()]],
      mentorId: ['', [Validators.required]],
      goal: ['', Validators.required],
      goalType: ['active', Validators.required],
    });
  }

  private validateDirection(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const direction = +control.value;
      return direction === 0 ? { required: true } : null;
    };
  }
}
