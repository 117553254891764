<h2 class="mb-16 text-4xl text-center">GoalMate Results</h2>

<!-- Swiper slider -->
<swiper-container #swiperRef goalmateSwiper [config]="swiperOpt" init="false">
  @for (post of posts; track post) {
  <swiper-slide>
    <goalmate-community-card [post]="post" />
  </swiper-slide>
  }
</swiper-container>

<!-- Swiper Pagination -->
<div class="flex justify-center pl-20 mt-8">
  <div class="swiper-pagination-community"></div>
</div>
