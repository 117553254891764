<div class="shadow-xl card rounded-xl bg-base-100" @dialogAnimation>
  <div class="card-body">
    <h2 class="mb-4 card-title">Edit goal</h2>
    <form [formGroup]="form" (ngSubmit)="onConfirm()">
      <input
        type="text"
        placeholder="Type goal here"
        class="flex-grow w-full input input-bordered"
        formControlName="goal"
        [ngClass]="{
          'border-error':
            form.controls.goal.touched && form.controls.goal.invalid
        }"
        (ngModelChange)="inapropriateContent.set(false)"
      />
      <div class="label">
        <span
          class="label-text-alt text-error"
          *ngIf="
            form.controls.goal.touched &&
            form.controls.goal.hasError('required')
          "
          >Goal is required</span
        >
        @if(inapropriateContent()) {
        <label class="label">
          <span class="label-text-alt text-error"
            >Inappropriate goal detected. Please, set another goal</span
          >
        </label>
        }
      </div>

      <div class="flex justify-end gap-4 mt-4 card-actions">
        <button
          [disabled]="isLoading()"
          class="btn btn-outline"
          (click)="onCancel()"
          type="button"
        >
          Cancel
        </button>
        <button [disabled]="isLoading()" class="btn btn-primary" type="submit">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
