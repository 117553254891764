import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { GoalmateMenuItemDirective } from './menu-item.directive';

@Component({
  selector: 'goalmate-menu-item',
  standalone: true,
  imports: [GoalmateMenuItemDirective],
  template: `
    <div goalmate-menu-item tabindex="0" (click)="clicked.emit()">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Output() clicked = new EventEmitter<void>();
}
