import { Pipe, PipeTransform } from '@angular/core';
import { GoalStatus } from '@goalmate/typings';

@Pipe({
  name: 'statusName',
  standalone: true,
})
export class StatusNamePipe implements PipeTransform {
  transform(status: GoalStatus | string | null): string {
    switch (status) {
      case GoalStatus.ACTIVE:
        return 'Active';
      case GoalStatus.COMPLETED:
        return 'Completed';
      case GoalStatus.PAUSED:
        return 'Paused';
      case GoalStatus.FUTURE:
        return 'Future';
      default:
        return '';
    }
  }
}
