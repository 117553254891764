import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NgIf, SlicePipe, UpperCasePipe } from '@angular/common';
import { User } from '@goalmate/typings';

@Component({
  selector: 'goalmate-avatar',
  standalone: true,
  imports: [NgIf, SlicePipe, UpperCasePipe],
  template: `
    <div
      class="rounded-full"
      [class.bg-info]="!user.photoURL"
      [class.w-6]="size === 'small'"
      [class.w-10]="size === 'normal'"
      [class.w-20]="size === 'large'"
    >
      <img
        *ngIf="user.photoURL"
        [src]="user.photoURL"
        referrerpolicy="no-referrer"
      />
      <span *ngIf="!user.photoURL" class="text-3xl text-base-100">{{
        user.displayName | slice : 0 : 1 | uppercase
      }}</span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input({ required: true }) user!: User;

  @Input() size: 'small' | 'normal' | 'large' = 'normal';

  @HostBinding('class.placeholder')
  get placeholder() {
    return !this.user?.photoURL;
  }

  @HostBinding('class.avatar')
  avatar = true;
}
