<div class="container px-6 mx-auto">
  <!-- Headers -->
  <h1
    class="pt-20 mx-auto text-5xl font-bold text-center md:text-7xl max-w-[600px]"
  >
    Reach your goals with AI mentor
  </h1>
  <h4 class="mx-auto mt-10 text-3xl text-center">
    Set, track, and achieve goals in all areas of your life with AI life
    coaches.
  </h4>
  <!--Start -->
  <div class="flex justify-center py-8 mt-4">
    <a
      [href]="appRoute"
      class="btn btn-white shadow !text-base-content !text-2xl btn-lg w-56 !rounded-full hover:bg-white"
    >
      Start
    </a>
  </div>

  <!-- Main picture -->

  <div
    class="w-[90vw] mx-auto overflow-hidden rounded-t-md md:rounded-t-lg shadow-2xl md:w-4/6"
  >
    <img src="assets/images/main-pic.png" alt="Main picture" />
  </div>
</div>
