import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { DialogData } from './dialog.interface';
import { dialog } from './dialog-animation';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'goalmate-dialog',
  standalone: true,
  imports: [NgIf, NgFor],
  template: `<div
    class="card rounded-xl shadow-xl bg-base-100"
    [@dialogAnimation]
  >
    <div class="card-body">
      <h2 class="card-title">{{ data.title }}</h2>
      <p *ngIf="strMessage">{{ strMessage }}</p>
      <ng-container *ngIf="arrMessage">
        <p *ngFor="let message of arrMessage">{{ message }}</p>
      </ng-container>

      <div class="card-actions flex justify-end gap-4 mt-4">
        <button
          *ngIf="!data.hideCancel"
          class="btn btn-outline"
          (click)="onCancel()"
        >
          Cancel
        </button>
        <button class="btn btn-primary" (click)="onConfirm()">Confirm</button>
      </div>
    </div>
  </div>`,
  styles: `:host {
    @apply mx-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class DialogComponent {
  data = inject<DialogData>(DIALOG_DATA);
  private dialorRef = inject(DialogRef);

  strMessage = typeof this.data.message === 'string' ? this.data.message : null;
  arrMessage = Array.isArray(this.data.message) ? this.data.message : null;

  onCancel() {
    this.dialorRef.close({ result: false });
  }

  onConfirm() {
    this.dialorRef.close({ result: true });
  }
}
