import { Injectable, signal } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebPlayerService {
  private player = new Audio();

  private _isPlaying = signal(false);
  isPlaying = this._isPlaying.asReadonly();

  constructor() {
    fromEvent(this.player, 'playing').subscribe(() => {
      this._isPlaying.set(true);
    });
    fromEvent(this.player, 'pause').subscribe(() => {
      this._isPlaying.set(false);
    });
  }

  play(src?: string) {
    if (src && this.player.src !== src) {
      this.player.src = src;
      this.player.load();
    }
    this.player.play();
  }

  preloadMetadata(src: string) {
    this.player.src = src;
    this.player.load();
  }

  pause() {
    this.player.pause();
  }
}
