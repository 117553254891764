import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  inject,
} from '@angular/core';
import {
  CdkOverlayOrigin,
  ConnectedPosition,
  OverlayModule,
} from '@angular/cdk/overlay';
import { MenuItemComponent } from './menu-item.component';
import { menu } from './menu-animation';
import { NgStyle } from '@angular/common';
import { Subscription } from 'rxjs';
import { GoalmateMenuItemDirective } from './menu-item.directive';

@Component({
  selector: 'goalmate-menu',
  standalone: true,
  imports: [OverlayModule, NgStyle],
  template: `
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPositions]="menuPositions"
      (backdropClick)="isOpen = false"
    >
      <div
        [@menuAnimation]
        class="card border border-base-300 dropdown w-full max-w-md shadow-lg py-2 bg-base-100 rounded-box overflow-hidden"
        [ngStyle]="{
          width: width,
          minWidth: minWidth,
          maxWidth: maxWidth
        }"
      >
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  animations: [menu],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterContentInit, OnDestroy {
  private cdr = inject(ChangeDetectorRef);
  @Input() trigger!: CdkOverlayOrigin;
  @Input() width = 'fit-content';
  @Input() maxWidth = '300px';
  @Input() minWidth = '200px';
  @Input() isOpen = false;
  @Input() closeOnClick = true;

  @ContentChildren(GoalmateMenuItemDirective)
  menuItemsD!: QueryList<GoalmateMenuItemDirective>;

  @ContentChildren(MenuItemComponent)
  menuItemsC!: QueryList<MenuItemComponent>;

  menuClickSub = new Subscription();

  menuPositions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  ngAfterContentInit(): void {
    this.menuClickSub.add(
      this.menuItemsD.changes.subscribe(
        (items: QueryList<GoalmateMenuItemDirective>) => {
          this.closeOnClicked(items);
        },
      ),
    );

    this.menuClickSub.add(
      this.menuItemsC.changes.subscribe(
        (items: QueryList<MenuItemComponent>) => {
          this.closeOnClicked(items);
        },
      ),
    );

    if (this.menuItemsD.length) {
      this.closeOnClicked(this.menuItemsD);
    }
    if (this.menuItemsC.length) {
      this.closeOnClicked(this.menuItemsC);
    }
  }

  private closeOnClicked(
    menuItems: QueryList<GoalmateMenuItemDirective | MenuItemComponent>,
  ) {
    if (!this.closeOnClick) return;

    menuItems.forEach((item) => {
      this.menuClickSub.add(
        item.clicked.subscribe(() => {
          this.isOpen = false;
          this.cdr.detectChanges();
        }),
      );
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.menuClickSub?.unsubscribe();
  }
}
