import { Injectable, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class DashboardSliderService {
  private _selectedSlide = signal<number>(0);
  private _selectedSlide$ = toObservable(this._selectedSlide);

  get selectedSlide$() {
    return this._selectedSlide$;
  }

  get selectedSlide(): Signal<number> {
    return this._selectedSlide.asReadonly();
  }

  set selectedSlide(value: number) {
    this._selectedSlide.set(value);
  }
}
