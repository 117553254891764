import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { SwiperDirective } from '@goalmate/directives';
import { MentorsSkillsComponent } from '@goalmate/presentational';
import { MentorsService } from '@goalmate/services';
import { Mentor } from '@goalmate/typings';
import { Swiper, SwiperOptions } from 'swiper/types';
import {
  ModalMentorBioComponent,
  ModalMentorBioComponentData,
} from '../modal-mentor-bio/modal-mentor-bio.component';
import { WebPlayerService } from '../../services/web-player.service';

@Component({
  selector: 'goalmate-mentors',
  standalone: true,
  imports: [SwiperDirective, NgTemplateOutlet, MentorsSkillsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './mentors.component.html',
  styleUrl: './mentors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorsComponent implements AfterViewInit {
  private dialog = inject(Dialog);
  private mentorsService = inject(MentorsService);
  private webPlayer = inject(WebPlayerService);

  isPlaying = this.webPlayer.isPlaying;

  @ViewChild('swiperRef') swiperRef!: ElementRef<HTMLElement>;

  protected mentors = this.mentorsService
    .getMentors()
    .map((m) => this.formatBio(m));

  swiper!: Swiper;

  selectedMentorId = signal('1');

  selectedMentor = computed(() => {
    // Set active mentor
    return this.mentors.find(
      (mentor) => mentor.id === this.selectedMentorId(),
    ) as Mentor;
  });

  swiperOpt: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    on: {
      slideChange: () => {
        const newMentorId = this.swiper.activeIndex + 1;
        this.selectedMentorId.set(newMentorId.toString());
        this.webPlayer.pause();
      },
    },
  };

  ngAfterViewInit(): void {
    this.swiper = (this.swiperRef.nativeElement as any).swiper as Swiper;
    this.webPlayer.preloadMetadata(this.selectedMentor().bioAudioUrl);
  }

  changeSlide(mentorId: string) {
    // Set active mentor
    this.selectedMentorId.set(mentorId);
    // Change slide to selected mentor
    const mentorIndex = this.mentors.findIndex((m) => m.id === mentorId);
    this.swiper.slideTo(mentorIndex);
  }

  onReadFullBio(bio: string, name: string, audio: string) {
    return this.dialog.open<
      ModalMentorBioComponent,
      ModalMentorBioComponentData
    >(ModalMentorBioComponent, {
      autoFocus: false,
      data: { bio, name, audio },
    });
  }

  onPlay(src: string) {
    this.webPlayer.play(src);
  }

  onStop() {
    this.webPlayer.pause();
  }

  private formatBio(m: Mentor) {
    const bio = m.bio;
    const truncatedBio = this.truncateText(bio, 500);
    const shortBioHtml = `<p>${truncatedBio}</p>`;
    const bioHtml = bio
      .split('\n')
      .map((text) => `<p>${text}</p>`)
      .join('');

    m.bio = bioHtml;
    return { ...m, shortBioHtml };
  }

  private truncateText(textContent: string, maxLength = 255): string {
    // If the content is shorter than the maximum length, return the entire content
    if (textContent.length <= maxLength) {
      return textContent;
    }

    // Find the first 255 characters without cutting the last word
    let truncated = textContent.slice(0, maxLength);

    // If the last character is not a space, extend to the full word
    if (textContent[maxLength] && !/\s/.test(textContent[maxLength])) {
      truncated = truncated.slice(0, truncated.lastIndexOf(' '));
    }

    return truncated.trim() + '...';
  }
}
