import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  inject,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { DisplayCardItemInputComponent } from './display-card-item-input.component';
import { DisplayCardItemDropdownComponent } from './display-card-item-dropdown.component';

@Component({
  selector: 'goalmate-display-card-item',
  standalone: true,
  imports: [NgClass],
  template: `<div
    class="collapse collapse-arrow bg-base-100"
    [ngClass]="isOpen ? 'collapse-open' : 'collapse-close'"
  >
    <input type="radio" name="{{ fieldName }}" (click)="arrowToggle()" />
    <div class="flex flex-wrap justify-between collapse-title">
      <div class="text-lg font-medium min-w-min mr-4 ">
        {{ fieldName }}
      </div>
      <div class="text-lg font-light">
        {{ fieldInput }}
      </div>
    </div>

    <div class="collapse-content">
      <ng-content></ng-content>
    </div>
  </div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCardItemComponent implements AfterContentInit {
  private cdr = inject(ChangeDetectorRef);
  @Input() fieldName: string | undefined = '';
  @Input() fieldInput: string | boolean | undefined = '';

  @ContentChild(DisplayCardItemInputComponent, { descendants: true })
  itemInput: DisplayCardItemInputComponent | undefined;

  @ContentChild(DisplayCardItemDropdownComponent, { descendants: true })
  dropdownInput: DisplayCardItemDropdownComponent | undefined;

  protected isOpen = false;

  ngAfterContentInit(): void {
    if (this.itemInput) {
      this.itemInput.fieldInput = this.fieldInput;
      this.itemInput.arrowToggle.subscribe(() => {
        this.arrowToggle();
      });
    }
    if (this.dropdownInput) {
      this.dropdownInput.arrowToggle.subscribe(() => {
        this.arrowToggle();
      });
    }
  }

  arrowToggle() {
    this.isOpen = !this.isOpen;
    this.cdr.markForCheck();
  }
}
