<goalmate-body-feature-wrapper [reverse]="true">
  <!-- Title -->
  <span feature-title>Timer and progress tracking</span>

  <!-- Description -->
  <p feature-description>
    A thoughtfully designed tracking system will help you keep focused on your
    goals and avoid distractions. Built-in timer and daily reports will keep
    your attention on goals to maintain constant progress and fuel your
    motivation.
  </p>

  <!-- Image -->
  <img feature-image src="assets/images/main-feature-pic.png" alt="Feature" />
</goalmate-body-feature-wrapper>
