import { inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { DialogComponent } from './dialog.component';
import { DialogData, DialogResponse } from './dialog.interface';
import {
  StartProgramComponent,
  StartProgramDialogData,
  StartProgramDialogResponse,
} from './start-program/start-program.component';
import {
  ChangeMentorComponent,
  ChangeMentorResponse,
} from './change-mentor/change-mentor.component';
import {
  UpdateGoalComponent,
  UpdateGoalDialogData,
} from './update-goal/update-goal.component';
import {
  CreateNewGoalComponent,
  CreateNewGoalComponentData,
  CreateNewGoalComponentRes,
} from './create-new-goal/create-new-goal.component';
import { FilterComponent } from '../filter/filter/filter.component';
import { FilterSection } from '../filter/filter.interface';
import {
  CompleteGoalComponent,
  CompleteGoalDialogData,
  CompleteGoalDialogResponse,
} from './complete-goal/complete-goal.component';
import { Goal } from '@goalmate/typings';

@Injectable()
export class DialogService {
  private dialog = inject(Dialog);

  getDialog() {
    return this.dialog;
  }

  open(data: DialogData) {
    const defaultData: Partial<DialogData> = {
      hideCancel: false,
    };
    return this.dialog.open<DialogResponse>(DialogComponent, {
      minWidth: '300px',
      data: { ...defaultData, ...data },
    });
  }

  startProgram(dueTime: string, timezone?: string) {
    return this.dialog.open<
      StartProgramDialogResponse,
      StartProgramDialogData,
      StartProgramComponent
    >(StartProgramComponent, {
      minWidth: '300px',
      data: { dueTime, timezone },
    });
  }

  updateMentor(selectedMentorId: string) {
    return this.dialog.open<ChangeMentorResponse>(ChangeMentorComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '50%',
      data: { selectedMentorId },
      autoFocus: false,
    });
  }

  updateGoal(goal: string) {
    return this.dialog.open<string, UpdateGoalDialogData, UpdateGoalComponent>(
      UpdateGoalComponent,
      {
        minWidth: '300px',
        maxWidth: '600px',
        width: '100%',
        autoFocus: false,
        data: { goal },
      },
    );
  }

  addNewGoal(activeProgram: boolean) {
    return this.dialog.open<
      CreateNewGoalComponentRes,
      CreateNewGoalComponentData
    >(CreateNewGoalComponent, {
      minWidth: '300px',
      maxWidth: '600px',
      width: '100%',
      autoFocus: false,
      data: { activeProgram },
    });
  }

  completeGoal(goal: Goal) {
    return this.dialog.open<CompleteGoalDialogResponse, CompleteGoalDialogData>(
      CompleteGoalComponent,
      {
        minWidth: '300px',
        data: { goal },
      },
    );
  }

  openFilter(filters: FilterSection[]) {
    return this.dialog.open<
      FilterSection[] | undefined,
      FilterSection[],
      FilterComponent
    >(FilterComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100%',
      autoFocus: false,
      data: filters,
    });
  }
}
