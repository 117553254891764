import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'goalmate-display-card-item-input',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule],
  template: ` <div class="form-control w-full ">
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
      <input
        type="text"
        class="input input-bordered w-full "
        formControlName="displayName"
      />
      <label
        class="label"
        *ngIf="form.controls.displayName.touched && form.invalid"
      >
        <span class="label-text-alt text-sm text-error"
          >Please, enter required information</span
        >
      </label>
      <div class="flex justify-end mt-4">
        <!-- Cancel button  -->
        <button
          type="button"
          class="btn btn-sm btn-circle bg-base-100"
          (click)="onArrowToggle()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <!-- Submit button  -->
        <button type="submit" class="btn btn-sm btn-circle bg-base-100 ml-3 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </button>
      </div>
    </form>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCardItemInputComponent implements OnChanges {
  @Input() fieldInput: string | boolean | undefined = '';
  @Output() arrowToggle = new EventEmitter<void>();
  @Output() valueChanged = new EventEmitter<string>();

  form = this.fb.nonNullable.group({
    displayName: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fieldInput']) {
      const displayName = changes['fieldInput'].currentValue;
      this.form.setValue({ displayName });
    }
  }

  onFormSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const displayName = this.form.controls.displayName.value;
    this.valueChanged.emit(displayName);
    this.onArrowToggle();
    this.form.markAsPristine();
    this.form.reset();
  }

  protected onArrowToggle() {
    this.arrowToggle.emit();
  }
}
