import { NgModule } from '@angular/core';
import { DisplayCardComponent } from './display-card.component';
import { DisplayCardItemComponent } from './display-card-item.component';
import { DisplayCardItemDropdownComponent } from './display-card-item-dropdown.component';
import { DisplayCardItemInputComponent } from './display-card-item-input.component';

@NgModule({
  imports: [
    DisplayCardComponent,
    DisplayCardItemComponent,
    DisplayCardItemDropdownComponent,
    DisplayCardItemInputComponent,
  ],
  exports: [
    DisplayCardComponent,
    DisplayCardItemComponent,
    DisplayCardItemDropdownComponent,
    DisplayCardItemInputComponent,
  ],
})
export class DisplayCardModule {}
