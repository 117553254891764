<h2 class="mb-16 text-4xl text-center">90-Day GoalMate Mentorship Program</h2>

<div class="max-w-2xl mx-auto">
  @for(prog of program; track $index) {
  <div class="mb-2 collapse collapse-arrow bg-base-200">
    <input type="radio" name="accordion" [checked]="$first ?? 'checked'" />
    <div class="text-xl font-medium collapse-title">
      {{ prog.chapter }}
    </div>
    <div class="collapse-content">
      <ul>
        @for(section of prog.sections; track $index) {
        <li class="p-2 text-lg">{{ section }}</li>
        }
      </ul>
    </div>
  </div>
  }

  <!-- Load more buton -->
  @if(isLoaded() === false) {
  <button
    class="mx-auto rounded-full !text-info btn btn-link"
    (click)="onLoadMore()"
  >
    Show more...
  </button>
  }
</div>
