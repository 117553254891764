<div class="container mx-auto">
  <h4 class="mt-6 mb-8 text-lg text-center text-base-300">Featured on</h4>
  <div class="flex flex-wrap justify-center gap-[100px]">
    <img
      src="assets/images/the-new-york-times-logo.png"
      alt="NY-Times-logo"
      class="object-contain object-center h-10"
    />
    <img
      src="assets/images/wired-logo-png.png"
      alt="Wired-logo"
      class="object-contain h-10"
    />
    <img
      src="assets/images/bloomberg_Logo.png"
      alt="Bloomberg-logo"
      class="object-contain h-10"
    />
    <img
      src="assets/images/life-hacker-logo.png"
      alt="Life-Hacker-logo"
      class="object-contain h-10"
    />
  </div>
</div>
