<div class="container mx-auto">
  <!-- Headers -->
  <h1
    class="max-w-4xl pt-20 mx-auto text-5xl font-bold text-center md:text-7xl text-dark"
  >
    Reach your goals <br />
    with AI mentor
  </h1>
  <h4
    class="max-w-[800px] mx-auto text-center text-xl md:text-2xl mt-10 font-normal"
  >
    A simple yet powerful app to set, track, and achieve goals in all areas of
    your life with a personalized AI-powered technology.
  </h4>
  <!-- Call to action button -->
  <a href="#">
    <img
      class="mx-auto mt-9 w-36"
      src="/assets/images/App_Store_Badge.svg"
      alt="app-store-logo"
    />
  </a>
  <!-- Main picture -->

  <img
    src="assets/images/main-pic.png"
    alt="Main picture"
    class="w-4/6 mx-auto mt-6"
  />

  <!-- Columns -->
</div>
