<div class="shadow-xl card rounded-xl bg-base-100" @dialogAnimation>
  <div class="card-body">
    <h2 class="mx-auto mb-6 text-center card-title">Add new goal</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- Goal name -->
      <div class="w-full mb-3 form-control">
        <label class="label">
          <span class="font-medium label-text">Set your goal</span>
        </label>
        <input
          type="text"
          placeholder="Type here"
          class="w-full input input-bordered"
          formControlName="goal"
          (ngModelChange)="inapropriateContent.set(false)"
        />
        <label
          class="label"
          *ngIf="
            form.controls.goal.touched &&
            form.controls.goal.hasError('required')
          "
        >
          <span class="label-text-alt text-error">Goal is required</span>
        </label>
        @if(inapropriateContent()) {
        <label class="label">
          <span class="label-text-alt text-error"
            >Inappropriate goal detected. Please, set another goal</span
          >
        </label>
        }
      </div>
      <!-- Goal category -->
      <div class="w-full mb-3 form-control">
        <label class="label">
          <span class="font-medium label-text">Choose goal category</span>
        </label>
        <select class="select select-bordered" formControlName="direction">
          <option disabled selected value="0">-- select category --</option>
          <option value="1">Finance</option>
          <option value="2">Health</option>
          <option value="3">Relationship</option>
          <option value="4">Joy</option>
          <option value="5">Other</option>
        </select>
        <label
          class="label"
          *ngIf="
            form.controls.direction.touched &&
            form.controls.direction.hasError('required')
          "
        >
          <span class="label-text-alt text-error"
            >Goal category is required</span
          >
        </label>
      </div>
      <!-- Mentor -->
      <div class="w-full mb-2 form-control">
        <label class="label">
          <span class="font-medium label-text">Choose mentor</span>
        </label>
        <select class="select select-bordered" formControlName="mentorId">
          <option disabled selected value="">-- select mentor --</option>
          @for (mentor of mentors; track mentor.id) {
          <option [value]="mentor.id">{{ mentor.name }}</option>
          }
        </select>

        <!-- Error message -->
        @if (form.controls.mentorId.touched &&
        form.controls.mentorId.hasError('required')) {
        <label class="label">
          <span class="label-text-alt text-error">Mentor is required</span>
          <div class="label w-fit" (click)="onCancel()">
            <a
              class="font-medium underline label-text-alt text-info"
              routerLink="/mentors"
              >Meet mentors</a
            >
          </div>
        </label>
        } @else {
        <!-- Link to Mentors page -->
        <label class="label">
          <span></span>
          <div class="label w-fit" (click)="onCancel()">
            <a
              class="font-medium underline label-text-alt text-info"
              routerLink="/mentors"
              >Meet mentors</a
            >
          </div>
        </label>
        }
      </div>
      <!-- Program status -->
      @if (data.activeProgram) {
      <div
        class="mx-auto max-w-max-[400px] flex justify-center gap-0 sm:gap-2 my-3"
      >
        <div class="form-control">
          <label class="cursor-pointer label">
            <span class="mr-3 text-lg font-light sm:text-xl label-text"
              >Active goal</span
            >
            <input
              type="radio"
              class="radio checked:bg-info"
              value="active"
              formControlName="goalType"
            />
          </label>
        </div>
        <div class="divider divider-horizontal"></div>
        <div class="form-control">
          <label class="cursor-pointer label">
            <span class="mr-3 text-lg font-light sm:text-xl label-text"
              >Future goal</span
            >
            <input
              type="radio"
              class="radio checked:bg-info"
              value="future"
              formControlName="goalType"
            />
          </label>
        </div>
      </div>
      }

      <!-- Buttons -->
      <div class="flex justify-end gap-4 mt-10 card-actions">
        <button
          [disabled]="isLoading()"
          type="button"
          class="btn btn-outline"
          (click)="onCancel()"
        >
          Cancel
        </button>
        <button [disabled]="isLoading()" type="submit" class="btn btn-primary">
          Add goal
        </button>
      </div>
    </form>
  </div>
</div>
