import { Component } from '@angular/core';
import { environment } from '@goalmate/environment';

@Component({
  selector: 'goalmate-first-screen',
  templateUrl: './first-screen.component.html',
  styleUrls: ['./first-screen.component.scss'],
  standalone: true,
})
export class FirstScreenComponent {
  appRoute = environment.host.app;
}
