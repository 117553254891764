import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@goalmate/environment';
import { catchError } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'goalmate-wait-list',
    templateUrl: './wait-list.component.html',
    styleUrls: ['./wait-list.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf,
    ],
})
export class WaitListComponent {
  waitlistForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
  });

  waitlistSuccess: boolean | undefined;

  @ViewChild('modal')
  modal!: ElementRef<HTMLDialogElement>;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
  ) {}

  onEmailSubmit() {
    if (this.waitlistForm.invalid) {
      this.waitlistForm.markAllAsTouched();
      return;
    }
    const email = this.waitlistForm.controls.email.value;

    //send POST request
    this.http
      .post<{ message: string }>(environment.api.emailWaitList, { email })
      .pipe(
        catchError((error) => {
          //show error message
          this.waitlistSuccess = false;
          this.cdr.markForCheck();
          this.modal.nativeElement.showModal();
          throw error;
        }),
      )
      .subscribe(() => {
        //show success message
        this.waitlistSuccess = true;
        this.cdr.markForCheck();
        this.modal.nativeElement.showModal();
        this.waitlistForm.reset();
      });
  }
}
