import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'goalmate-filter-section',
  standalone: true,
  imports: [NgClass],
  template: `
    <h3 class="truncate !text-xl !font-medium mb-5">
      {{ title() }}
    </h3>
    <div class="grid grid-cols-2 gap-y-3 gap-x-6">
      <ng-content></ng-content>
    </div>
    @if(bottomBorder()) {
    <hr class="border-t border-base-300 mt-4" />
    }
  `,
  styles: `:host {
    @apply mb-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSectionComponent {
  title = input.required<string>();
  key = input.required<string>();
  bottomBorder = input<boolean>(true);
}
