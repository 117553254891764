import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormsModule } from '@angular/forms';
import { TimezoneService } from '@goalmate/services';
import { dialog } from '../dialog-animation';
import { DatePipe } from '@angular/common';

export interface StartProgramDialogData {
  dueTime: string;
  timezone?: string;
}

export type StartProgramDialogResponse = DialogResponseConrirm &
  DialogResponseCancel;

interface DialogResponseConrirm {
  result: boolean;
  timezone: string;
  nextCheck: Date;
}

interface DialogResponseCancel {
  result: boolean;
}

@Component({
  standalone: true,
  imports: [FormsModule, DatePipe],
  template: `
    <div class="card rounded-xl shadow-xl bg-base-100" @dialogAnimation>
      <div class="card-body">
        <h2 class="card-title">Start Program</h2>
        <p class="mb-4">
          You will be required to text your mentors every day about your daily
          progress for each active goal.
        </p>

        <p class="mb-4">
          The next cut off time is
          <strong>{{ localDueDate() }}</strong>
        </p>

        <p class="mb-0 font-medium">Confirm your timezone</p>
        <!-- Timezone -->
        <label class="form-control w-full max-w-xs">
          <select
            class="select select-bordered"
            [ngModel]="myTimezone()"
            (ngModelChange)="myTimezone.set($event)"
          >
            @for(timezone of timezoneOptions(); track $index) {
            <option>{{ timezone }}</option>
            }
          </select>
        </label>

        <div class="card-actions flex justify-end gap-4 mt-4">
          <button class="btn btn-outline" (click)="onCancel()">Cancel</button>
          <button class="btn btn-primary" (click)="onConfirm()">Confirm</button>
        </div>
      </div>
    </div>
  `,
  styles: `:host {
    @apply mx-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class StartProgramComponent implements OnInit {
  private dialorRef = inject(DialogRef);
  private dialogData = inject<StartProgramDialogData>(DIALOG_DATA);
  private timezoneService = inject(TimezoneService);

  dueDate = signal<Date | undefined>(undefined);
  localDueDate = signal<string>('');
  myTimezone = signal<string>('');
  timezoneOptions = signal<string[]>([]);

  ngOnInit(): void {
    this.setTimezoneOptions();
    this.setNextCheckTime();
    this.setNextCheckInLocalTime();
  }

  /**
   * Closes the dialog and returns a result of false.
   */
  onCancel() {
    this.dialorRef.close({ result: false });
  }

  /**
   * Closes the dialog and returns a result of true,
   * along with the selected timezone and next check date.
   */
  onConfirm() {
    this.dialorRef.close({
      result: true,
      timezone: this.myTimezone(),
      nextCheck: this.dueDate(),
    });
  }

  /**
   * Sets the next check date based on the due time and timezone from dialog data.
   */
  private setNextCheckTime() {
    const dueTime = this.dialogData.dueTime;
    const timezone =
      this.dialogData.timezone || this.timezoneService.getMyTimezone();
    const nextCheck = this.timezoneService.getNextCheckDate(dueTime, timezone);
    this.dueDate.set(nextCheck);
  }

  /**
   * Sets the next check date in local time
   */
  private setNextCheckInLocalTime() {
    const dueTime = this.dialogData.dueTime;
    const nextCheck = this.timezoneService.getNextCheckLocalDate(dueTime);
    this.localDueDate.set(nextCheck);
  }

  /**
   * Sets the timezone options by fetching the list of timezones from the timezone service.
   * Calls selectMyTimezone to set the default timezone.
   */
  private async setTimezoneOptions() {
    const timezones = await this.timezoneService.getTimezones();
    this.timezoneOptions.set(timezones);
    this.selectMyTimezone();
  }

  /**
   * Selects the user's timezone from the list of timezone options.
   */
  private selectMyTimezone() {
    const myTimezone =
      this.dialogData.timezone || this.timezoneService.getMyTimezone();
    this.myTimezone.set(myTimezone);
  }
}
