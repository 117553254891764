import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'goalmate-display-card-item-dropdown',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor],
  template: `<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <select
      class="select select-bordered w-full mb-4 font-normal"
      formControlName="value"
    >
      <option *ngFor="let option of dropdownOptions" [value]="option">
        {{ option }}
      </option>
    </select>
    <label class="label" *ngIf="form.invalid && form.controls.value.touched">
      <span class="label-text-alt text-sm text-error"
        >Plesae, enter required information</span
      >
    </label>
    <div class="flex justify-end">
      <!-- Cancel button  -->
      <button
        type="button"
        class="btn btn-sm btn-circle bg-base-100"
        (click)="onArrowToggle()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <!-- Submit button  -->
      <button type="submit" class="btn btn-sm btn-circle bg-base-100 ml-3 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      </button>
    </div>
  </form>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCardItemDropdownComponent implements OnChanges, OnInit {
  private fb = inject(FormBuilder);

  @Input({ required: true }) dropdownOptions!: string[];
  @Input() dropdownSelection: string | boolean | undefined = '';

  @Output() arrowToggle = new EventEmitter<void>();
  @Output() valueChanged = new EventEmitter<string>();

  protected form = this.fb.nonNullable.group({
    value: ['', Validators.required],
  });

  ngOnInit(): void {
    const value = this.dropdownSelection?.toString() || '';
    this.form.controls.value.setValue(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dropdownSelection']?.currentValue) {
      const value = changes['dropdownSelection']?.currentValue || '';
      this.form.controls.value.setValue(value);
    }
  }

  protected onArrowToggle() {
    this.arrowToggle.emit();
  }

  protected onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { value } = this.form.value;

    this.valueChanged.emit(value);
    this.arrowToggle.emit();
    this.form.reset();
    this.form.markAsPristine();
  }
}
