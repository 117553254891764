<div class="container flex flex-wrap justify-center gap-8 py-24 mx-auto">
  <div
    class="flex flex-none shadow-md card bg-base-200 text-primary-content card-compact w-72"
  >
    <div class="card-body">
      <i class="mx-auto text-7xl material-icons text-info">question_answer</i>
      <h2 class="mx-auto text-3xl card-title text-base-500">AI mentor</h2>
      <p class="text-xl text-center text-base-700">
        guide you along the journey to set and achieve your goals
      </p>
    </div>
  </div>
  <div
    class="flex flex-none shadow-md card bg-base-200 text-primary-content card-compact w-72"
  >
    <div class="card-body">
      <i class="mx-auto text-7xl material-icons text-info">alarm_on</i>
      <h2 class="mx-auto text-3xl card-title text-base-500">Timer</h2>
      <p class="text-xl text-center">
        and daily reports keep your focus on the most important things
      </p>
    </div>
  </div>
  <div
    class="flex flex-none shadow-md card bg-base-200 text-primary-content card-compact w-72"
  >
    <div class="card-body">
      <i class="mx-auto text-7xl material-icons text-info">change_circle</i>
      <h2 class="mx-auto text-3xl card-title text-base-500">Balanced</h2>
      <p class="text-xl text-center">
        approach help you make improvements in all areas of your life
      </p>
    </div>
  </div>
  <div
    class="flex flex-none shadow-md card bg-base-200 text-primary-content card-compact w-72"
  >
    <div class="card-body">
      <i class="mx-auto text-7xl material-icons text-info">track_changes</i>
      <h2 class="mx-auto text-3xl card-title text-base-500">Track</h2>
      <p class="text-xl text-center">
        overall progress and work on areas that need attention
      </p>
    </div>
  </div>
</div>
