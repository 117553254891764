<div class="mx-auto my-16 max-w-[1200px]">
  <div class="flex justify-center">
    <i class="max-w-xl pt-0 mr-2 text-5xl material-icons text-base-100"
      >format_quote</i
    >

    <blockquote
      class="pl-4 text-2xl font-light leading-8 text-left border-l-4 text-base-200"
    >
      To acheive your dreams you must apply discipline, but more importantly
      <span class="font-bold text-secondary">consistency</span>. Without
      commitment you'll never start, but without consistency you'll never
      finish.
    </blockquote>
  </div>
  <div class="flex justify-end">
    <h4 class="text-lg font-bold text-right text-base-200">
      Denzel Washington
    </h4>
  </div>
  <div class="flex justify-end">
    <span class="text-base font-light text-base-300"
      >Iconic American actor</span
    >
  </div>
</div>
