<goalmate-navbar></goalmate-navbar>
<goalmate-first-screen></goalmate-first-screen>
<goalmate-second-section></goalmate-second-section>
<!-- <goalmate-featured-on></goalmate-featured-on> -->
<goalmate-main-features></goalmate-main-features>
<goalmate-body-feature-ai></goalmate-body-feature-ai>
<goalmate-body-feature-tracker></goalmate-body-feature-tracker>
<goalmate-body-feature-balance></goalmate-body-feature-balance>
<goalmate-wait-list></goalmate-wait-list>
<goalmate-footer></goalmate-footer>
