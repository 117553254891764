import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private breakpointObserver = inject(BreakpointObserver);

  private breakpoints = ['(max-width: 1024px)'];
  private breakpointObserver$ = this.breakpointObserver.observe(
    this.breakpoints,
  );
  private breakpointSignal = toSignal(this.breakpointObserver$);

  readonly isMobile = computed(() =>
    this.getBreakpoints(this.breakpointSignal()),
  );

  private _isIOS = signal(false);
  readonly isIOS = this._isIOS.asReadonly();

  constructor() {
    this.watchPlatform();
  }

  private getBreakpoints(result: BreakpointState | undefined) {
    return result ? result.matches : false;
  }

  private watchPlatform() {
    const isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) && navigator.maxTouchPoints > 1;

    this._isIOS.set(isIOS);
  }
}
