import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
    selector: 'goalmate-body-feature-wrapper',
    templateUrl: './body-feature-wrapper.component.html',
    styleUrls: ['./body-feature-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class BodyFeatureWrapperComponent {
  @Input() reverse = false;
  @HostBinding('style.backgroundColor')
  @Input()
  bg = 'hsl(var(--b1))'; // base-100
}
