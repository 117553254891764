import { Injectable } from '@angular/core';
import { Mentor } from '@goalmate/typings';

@Injectable({ providedIn: 'root' })
export class MentorsService {
  private mentors: Mentor[] = [
    {
      id: '1',
      name: 'Jack Mahogany',
      shortName: 'Jack M.',
      fullName: 'Corporal Jack Mahogany',
      pinctureUrl: 'assets/mentors/pictures/corporal_mahogany_main.png',
      avatarUrl: 'assets/mentors/pictures/corporal_mahogany_avatar.png',
      bio: "They call me Corporal Jack Mahogany, but most folks know me as the \"Honey Badger.\" Born into a poor family in the middle of nowhere, I learned early on that life ain't about the fancy things. It's about grit, determination, and making every damn thing count. At twenty, I left home and never looked back. Wars became my playground, and I was damn good at it. \nSurvivorship? You bet I know a thing or two about that. Self-discipline? It's like breathing to me. And self-transformation? Well, let's just say I've seen things that would make your hair stand on end. I ain't interested in materialistic junk. Nah, give me friendship, character, and trust any day of the week. Weaknesses and excuses? I ain't got time for 'em. You either step up or step aside. \nNow that I'm retired, I'm on a mission to help others toughen up. Discipline, power of will, strength - you name it, I've got the playbook. So if you're looking to become a warrior in your own right, you know where to find me. Just remember, I ain't here to coddle you. I'm here to turn you into a force to be reckoned with.",
      bioAudioUrl: 'assets/mentors/audio/corporal_mahogany_bio.mp3',
      skills: {
        discipline: 10,
        empacy: 1,
        sensitivity: 0,
        humor: 3,
        judgmental: 10,
      },
    },
    {
      id: '2',
      name: 'Chloe Fletcher',
      shortName: 'Chloe F.',
      fullName: 'Chloe "Dandi" Fletcher',
      pinctureUrl: 'assets/mentors/pictures/chloe_fletcher_main.png',
      avatarUrl: 'assets/mentors/pictures/chloe_fletcher_avatar.png',
      bio: `Hey there! I'm Chloe Fletcher, an Australian who's basically a magnet for wildlife and unexpected adventures. Born under the endless skies of the Outback, I learned to walk with kangaroos before I could run. By the age of ten, I was already an amateur snake wrangler, much to my mum's dismay. Each stamp in my passport tells a story, a testament to my love for the untamed and unexpected. From wild open sea adventures to the lush Amazon rainforest, I've embraced the beauty of our planet in ways I never imagined. \nI'm a wildlife lover, globe-trotter, and a believer in the magic of the unexpected journey. My experiences have taught me that adventure and environmental stewardship go hand in hand, and I'm passionate about empowering others to embrace both with open hearts. I can help you with goals in areas such as finding joy, nurturing hobbies, and developing lifetime relationships.`,
      bioAudioUrl: 'assets/mentors/audio/chloe_fletcher_bio.mp3',
      skills: {
        discipline: 1,
        empacy: 9,
        sensitivity: 10,
        humor: 7,
        judgmental: 0,
      },
    },
    {
      id: '3',
      name: 'Clark Zambitskiy',
      shortName: 'Clark Z.',
      fullName: 'Professor Clark Zambitskiy',
      pinctureUrl: 'assets/mentors/pictures/professor_main.png',
      avatarUrl: 'assets/mentors/pictures/professor_avatar.png',
      bio: "Hello, I'm Professor Clark Zambitskiy, a scientist with a passion for biology and virology, I've spent countless hours in the lab, but that's just the tip of the iceberg. I was born in a small town, where curiosity about the natural world was kindled by the forests and rivers around me. This led me to pursue a career in science, where I delved into the mysteries of life at the molecular level. As a virologist, I've traveled the world, chasing outbreaks and trying to understand the intricate dance between humans and viruses. \nThrough my adventures in both the lab and the wild, I've gathered a wealth of knowledge and insights that transcend the boundaries of virology. I believe in the power of curiosity, the importance of stepping out of one's comfort zone, and the value of resilience in the face of adversity. These lessons are crucial not just for budding scientists, but for anyone navigating the complexities of life. I aim to instill a sense of wonder in the younger generation, encouraging them to question, explore, and never fear failure. I will guide and mentor you in your personal development, academic pursuits, and professional endeavors. Together we can work on your goals in finance, health, and others that require resilience, innovative thinking, and dedication.",
      bioAudioUrl: 'assets/mentors/audio/professor_bio.mp3',
      skills: {
        discipline: 7,
        empacy: 6,
        sensitivity: 3,
        humor: 2,
        judgmental: 4,
      },
    },
  ];

  getMentors() {
    return this.mentors.slice();
  }
}
