import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionNamePipe } from './direction-name/direction-name.pipe';
import { StatusNamePipe } from './status-name/status-name.pipe';
import { ErrorMsgPipe } from './error-msg/error-msg.pipe';

@NgModule({
  imports: [CommonModule, DirectionNamePipe, StatusNamePipe, ErrorMsgPipe],
})
export class PipesModule {}
