import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { Goal, User } from '@goalmate/typings';
import { AvatarComponent } from '../avatar/avatar.component';
import { GoalToImgService, PostsService } from '@goalmate/services';

@Component({
  selector: 'goalmate-completed-goal-card',
  standalone: true,
  imports: [DatePipe, AvatarComponent],
  templateUrl: './completed-goal-card.component.html',
  styleUrl: './completed-goal-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedGoalCardComponent implements OnInit, OnDestroy {
  private goalToImg = inject(GoalToImgService);
  private postsService = inject(PostsService);

  goal = input.required<Goal>();
  user = input.required<User>();

  publish = output<{ goal: Goal; image: string }>();
  unpublish = output<Goal>();

  img = signal<string | undefined>(undefined);

  ngOnInit() {
    this.setPostImg();
  }

  onUnublish() {
    const goal = this.goal();
    this.unpublish.emit(goal);
  }

  onPublish() {
    const goal = this.goal();
    const image = this.img() as string;
    const base64 = image.split(',')[1];
    const event = { goal, image: base64 };
    this.publish.emit(event);
  }

  makeContent() {
    return this.postsService.makeContent(this.goal());
  }

  ngOnDestroy() {
    const img = this.img();
    if (img) URL.revokeObjectURL(img);
  }

  private async setPostImg() {
    const postImgs = this.goal().postImgs;
    let img: string;
    if (postImgs && postImgs.length) {
      img = postImgs[0];
    } else {
      img = await this.goalToImg.makeImg(this.goal());
    }
    this.img.set(img);
  }
}
