import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Timestamp } from 'firebase/firestore';
import { Observable, distinctUntilChanged, filter, interval, map } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';

interface TimeComponents {
  seconds: string;
  minutes: string;
  hours: string;
}

@Component({
  selector: 'goalmate-timer',
  standalone: true,
  imports: [NgIf, AsyncPipe, NgClass, NgTemplateOutlet, LoaderComponent],
  template: `
    <!-- Completed message -->
    <div *ngIf="completedForTheDay" class="flex items-center gap-x-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-7 h-7 text-warning"
      >
        <path
          fill-rule="evenodd"
          d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207 6.72 6.72 0 00.857-3.294z"
          clip-rule="evenodd"
        />
      </svg>
      <p class="text-sm font-medium">Completed</p>
    </div>
    <!-- Timer -->
    @if(!completedForTheDay && (timer$ | async); as timer ) {
    <div class="flex gap-x-2">
      <ng-container
        *ngTemplateOutlet="
          size === 'large' ? large : small;
          context: { $implicit: timer }
        "
      ></ng-container>
    </div>
    } @else if(!completedForTheDay && (timer$ | async) === null) {
    <ng-container *ngTemplateOutlet="loader"></ng-container>
    }
    <!-- Lagre timer template -->
    <ng-template #large let-timer>
      <div>
        <span class="countdown font-mono text-2xl"
          ><span [style.--value]="timer.hours"></span></span
        >{{ +timer.hours > 1 ? 'hours' : 'hour' }}
      </div>
      <div>
        <span class="countdown font-mono text-2xl"
          ><span [style.--value]="timer.minutes"></span></span
        >{{ +timer.minutes > 1 ? 'minutes' : 'minute' }}
      </div>
      <ng-container *ngIf="displaySeconds">
        <div>
          <span class="countdown font-mono text-2xl"
            ><span [style.--value]="timer.seconds"></span></span
          >seconds
        </div>
      </ng-container>
    </ng-template>
    <!-- Small timer template -->
    <ng-template #small let-timer>
      <span class="countdown font-mono text-2xl">
        <span [style.--value]="timer.hours"></span>:
        <span [style.--value]="timer.minutes"></span>
        @if(displaySeconds) { :
        <span [style.--value]="timer.seconds"></span>
        }
      </span>
    </ng-template>

    <ng-template #loader>
      <goalmate-loader />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent implements OnInit {
  @Input() time: Date | undefined;
  @Input() displaySeconds = false;
  @Input() completedForTheDay: boolean | undefined;
  @Input() size: 'large' | 'small' = 'large';

  protected timer$: Observable<TimeComponents> | undefined;

  ngOnInit() {
    this.timer$ = interval(1000).pipe(
      filter(() => !!this.time && !this.completedForTheDay),
      map(() => {
        const time = this.setTimer(this.time as Date);
        if (!this.displaySeconds) {
          time.seconds = '00';
        }
        return time;
      }),
      distinctUntilChanged(
        (prev, cur) =>
          prev.seconds === cur.seconds && prev.minutes === cur.minutes,
      ),
    );
  }

  private setTimer(time: Date): TimeComponents {
    const checkDay = new Date(time).getTime();

    const milliSecondsInASecond = 1000;
    // const hoursInADay = 24; // Uncomment if want to display hours without days
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const serverToday = Timestamp.now().toDate().getTime();

    const timeDifference = checkDay - serverToday;

    if (timeDifference <= 0) {
      return { seconds: '00', minutes: '00', hours: '00' };
    }

    const hh = Math.floor(
      timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute), // % hoursInADay, // Uncomment if want to display hours without days
    );
    const hours = hh < 10 ? '0' + hh : hh.toString();

    const min = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute,
    );
    const minutes = min < 10 ? '0' + min : min.toString();

    const sec =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
    const seconds = sec < 10 ? '0' + sec : sec.toString();

    return { seconds, minutes, hours };
  }
}
