<!-- Goal card  -->
<div
  class="w-full overflow-hidden max-w-[450px] shadow-lg card border border-base-300 bg-white text-black"
>
  <div class="max-w-[450px] max-h-[450px] h-auto w-full">
    @if(img()) {
    <img
      [src]="img()"
      height="500"
      width="auto"
      alt="Goal"
      [attr.download]="goal().id + '.png'"
    />
    } @else {
    <div class="flex items-center justify-center w-full h-full my-[220px]">
      <span class="loading loading-spinner loading-lg"></span>
    </div>
    }
  </div>
  <div class="px-4 py-4 card-body">
    <div class="flex gap-3">
      <goalmate-avatar [user]="user()" [size]="'small'" />
      <p class="font-medium">{{ user().displayName }}</p>
    </div>
    <p class="text-start">
      {{ goal().postContent || makeContent() }}
    </p>

    <div class="flex items-center justify-between mt-2">
      <!-- Social icons  -->
      <div class="hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-warning"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
          />
        </svg>
        <span class="ml-1">0</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 ml-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
          />
        </svg>
        <span class="ml-1">0</span>
      </div>

      <!-- Share post  -->
      @if(goal().postId) {
      <button
        (click)="onUnublish()"
        class="ml-0 font-normal text-black rounded-2xl btn btn-outline btn-sm"
      >
        Unpublish
      </button>
      } @else {
      <button
        (click)="onPublish()"
        class="ml-0 font-normal text-black rounded-2xl btn btn-outline btn-sm"
      >
        Publish
      </button>
      }
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-7 h-7"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
          />
        </svg>
      </button>
    </div>
    <div class="justify-end card-actions"></div>
  </div>
</div>
