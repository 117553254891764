import { ChangeDetectionStrategy, Component, model } from '@angular/core';
import { FilterContentItem } from '../filter.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'goalmate-filter-item',
  standalone: true,
  imports: [FormsModule],
  template: `
    <div class="form-control">
      <label class="label cursor-pointer">
        <span class="label-text">{{ filter().label }}</span>
        <input
          type="checkbox"
          checked="checked"
          class="checkbox"
          [disabled]="!!filter().disabled"
          [ngModel]="filter().selected"
          (ngModelChange)="updateValue($event)"
        />
      </label>
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterItemComponent {
  filter = model.required<FilterContentItem>();

  updateValue(selected: boolean) {
    this.filter.update((filter) => ({ ...filter, selected }));
  }
}
