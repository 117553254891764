import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BodyFeatureWrapperComponent } from '../body-feature-wrapper/body-feature-wrapper.component';

interface Description {
  title: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  isReversed: boolean;
  bg: string;
}

@Component({
  selector: 'goalmate-descriptions',
  standalone: true,
  imports: [BodyFeatureWrapperComponent],
  templateUrl: './descriptions.component.html',
  styleUrl: './descriptions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionsComponent {
  readonly descriptions: Description[] = [
    {
      title: 'Balance Your Life',
      description: `Create a life balance and reach your goals without struggle. Achieve harmony
    by improving and maintaining balance in key areas: finance, health,
    relationships, joy, and contribution to society.`,
      imgSrc: 'assets/images/desc-4.png',
      imgAlt: 'Balance Your Life',
      isReversed: false,
      bg: 'oklch(var(--b2))',
    },
    {
      title: 'Daily Accountability & Lasting Results',
      description: `Always stay on track. Key functionalities such as daily progress reports, built-in countdown, and AI-powered feedback will keep you constantly focused on your goals.`,
      imgSrc: 'assets/images/desc-2.png',
      imgAlt: 'Daily Accountability & Lasting Results',
      isReversed: true,
      bg: 'oklch(var(--b1))',
    },
    {
      title: 'Personalized AI Mentorship',
      description: `Receive instant feedback, guidance, and support from your AI mentors. AI coaches will guide you through challenges, celebrate your successes, and keep you accountable every step of the way.`,
      imgSrc: 'assets/images/desc-3.png',
      imgAlt: 'Personalized AI Mentorship',
      isReversed: false,
      bg: 'oklch(var(--b2))',
    },
    {
      title: 'Build Powerful Habits',
      description: `Create life-lasting, positive habits that improve your health, productivity, and happiness with a 90-Day GoalMate mentorship program.`,
      imgSrc: 'assets/images/desc-5.png',
      imgAlt: 'Build Powerful Habits',
      isReversed: true,
      bg: 'oklch(var(--b1))',
    },
    {
      title: 'Get Inspiration & Influence others',
      description: `Join GoalMate Community to share your achievements, build strong connections, and find inspiration from other goal getters.`,
      imgSrc: 'assets/images/desc-1.png',
      imgAlt: 'Get Inspiration & Influence others',
      isReversed: false,
      bg: 'oklch(var(--b2))',
    },
  ];
}
