<!-- Image - left side -->
<div class="flex-col justify-center hidden md:flex">
  <img [src]="post().imgs[0]" />
</div>

<!-- Comments - right side -->
<div class="flex flex-col sm:min-w-[400px] w-full md:max-w-[400px] p-2">
  <!-- User info -->
  <div class="flex flex-wrap items-center gap-3 mb-4">
    <!-- Avatar -->
    <div class="avatar">
      <div class="w-8 rounded-full">
        <img [src]="post().user.avatar" width="32" height="32" />
      </div>
    </div>
    <!-- User name -->
    <p class="font-medium grow">
      <strong>{{ post().user.name }}</strong>
    </p>
    <!-- Close button -->
    <button class="self-start" (click)="onClose()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    </button>
    <hr class="w-full border-b-0 border-base-200" />
  </div>

  <!-- Comments -->
  <div class="overflow-auto grow">
    <!-- User comment -->
    <goalmate-post-comment [comment]="post().userComment" class="mb-4" />
    <!-- Other comments -->
    @for(comment of post().comments; track comment.id) {
    <goalmate-post-comment [comment]="comment" class="mb-4" />
    }
  </div>

  <!-- New comment -->
  <form class="flex gap-2" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="w-full form-control">
      <textarea
        (keydown)="onKeydown($event)"
        #autosize="cdkTextareaAutosize"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        [cdkAutosizeMaxRows]="5"
        formControlName="message"
        class="w-full textarea textarea-primary border-t-0 border-x-0 rounded-none resize-none border border-b-[2px] text-lg"
        placeholder="Add a comment..."
        [class.border-error]="
          form.controls.message.dirty && form.controls.message.invalid
        "
        (ngModelChange)="inapropriateContent.set(false)"
      ></textarea>
      @if(inapropriateContent()) {
      <label class="label">
        <span class="label-text-alt text-error"
          >Inappropriate comment detected</span
        >
      </label>
      }
    </div>
    @if(form.controls.message.dirty && form.controls.message.valid) {
    <button
      class="self-center btn btn-outline btn-sm btn-secondary"
      [disabled]="isLoading()"
    >
      Post
    </button>
    }
  </form>
</div>
