import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  GetResponseData,
  GetResponseRes,
  SendVerificatoinLinkData,
} from '@goalmate/typings';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  private functions = inject(Functions);

  async sendWelcomeEmail(userId: string) {
    const func = this.getFunction<{ userId: string }, void>(
      'users-sendWelcomeEmail',
    );
    return func({ userId });
  }

  async sendVerificationLink(data: SendVerificatoinLinkData) {
    const func = this.getFunction<SendVerificatoinLinkData, void>(
      'users-sendVerificationLink',
    );
    return func(data);
  }

  async updateUserEmailByUserIdOrEmail(userId: string | null, email?: string) {
    const func = this.getFunction<
      { userId: string | null; email?: string },
      void
    >('users-updateUserEmailByUserIdOrEmail');
    return func({ userId, email });
  }

  async goalMateGetResponse(goalId: string) {
    const func = this.getFunction<GetResponseData, GetResponseRes>(
      'goalMate-getResponse',
    );
    const response = await func({ goalId });
    return response.data;
  }

  private getFunction<Req, Res>(name: string) {
    return httpsCallable<Req, Res>(this.functions, name);
  }
}
