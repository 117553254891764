import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { dialog } from '../dialog-animation';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { ModerationService } from '@goalmate/services';
import { finalize } from 'rxjs';

export interface UpdateGoalDialogData {
  goal: string;
}

@Component({
  selector: 'goalmate-update-goal',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgIf],
  templateUrl: './update-goal.component.html',
  styles: `:host {
    @apply mx-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class UpdateGoalComponent {
  private dialorRef = inject(DialogRef);
  private fb = inject(FormBuilder);
  private data = inject<UpdateGoalDialogData>(DIALOG_DATA);
  private moderationService = inject(ModerationService);

  inapropriateContent = signal(false);
  isLoading = signal(false);

  form = this.fb.nonNullable.group({
    goal: [this.data.goal, Validators.required],
  });

  onCancel() {
    this.dialorRef.close();
  }

  onConfirm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading.set(true);
    const goal = this.form.value.goal;
    this.moderationService
      .moderate(goal as string)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe((passed) => {
        if (!passed) {
          this.inapropriateContent.set(true);
          return;
        }
        this.dialorRef.close(goal);
      });
  }
}
