<!-- Header -->
<div class="flex justify-between mb-3">
  <h3 class="text-2xl font-medium">{{ data.name }}</h3>
  <button (click)="onClose()" class="self-start">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-8 h-8"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  </button>
</div>

<!-- Player buttosn -->
@if(isPlaying()) {
<button (click)="onStop()" class="flex gap-1 mb-1 w-fit">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
  Pause
</button>
} @else {
<button (click)="onPlay()" class="flex gap-1 mb-1 w-fit">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
    />
  </svg>
  Play
</button>
}

<!-- Bio -->
<div
  [innerHTML]="data.bio"
  class="flex flex-col gap-4 overflow-scroll text-lg grow"
></div>
