import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import {
  CommunityCardComponent,
  CommunityPost,
} from '../community-card/community-card.component';
import { GoalDirection } from '@goalmate/typings';
import { SwiperDirective } from '@goalmate/directives';
import { SwiperOptions } from 'swiper/types';
import { Pagination } from 'swiper/modules';

@Component({
  selector: 'goalmate-community',
  standalone: true,
  imports: [CommunityCardComponent, SwiperDirective],
  templateUrl: './community.component.html',
  styleUrl: './community.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommunityComponent {
  swiperOpt: SwiperOptions = {
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination-community',
      dynamicBullets: true,
    },
    slidesPerView: 5,
    spaceBetween: 0,
    initialSlide: 3,
    loop: true,
    freeMode: true,
    autoHeight: false,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      600: {
        slidesPerView: 2.2,
      },
      800: {
        slidesPerView: 2.8,
      },
      1000: {
        slidesPerView: 3.5,
      },
      1200: {
        slidesPerView: 4.2,
      },
      1700: {
        slidesPerView: 5,
      },
    },
  };

  private _posts: CommunityPost[] = [
    {
      goal: 'Vacation in Dubai with my girlfriend',
      authorName: 'Michael GGG',
      authorAvatar: 'assets/images/avatars/profile-pic.jpg',
      goalDirection: GoalDirection.RELATIONSHIP,
    },
    {
      goal: 'Go to gym 4 times a week for 3 months',
      authorName: 'Michael GGG',
      authorAvatar: 'assets/images/avatars/profile-pic.jpg',
      goalDirection: GoalDirection.HEALTH,
    },
    {
      goal: 'Go to RHCP concert in Dallas',
      authorName: 'Michael GGG',
      authorAvatar: 'assets/images/avatars/profile-pic.jpg',
      goalDirection: GoalDirection.JOY,
    },
    {
      goal: 'Get a yearly bonus at work',
      authorName: 'Michael GGG',
      authorAvatar: 'assets/images/avatars/profile-pic.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: 'Lose 15 pounds in 3 months',
      authorName: 'Michael GGG',
      authorAvatar: 'assets/images/avatars/profile-pic.jpg',
      goalDirection: GoalDirection.HEALTH,
    },
    {
      goal: 'Read 2 books in 1 months',
      authorName: 'kinoman104',
      authorAvatar: 'assets/images/avatars/kinoman104.png',
      goalDirection: GoalDirection.OTHER,
    },
    {
      goal: 'Save $10,000',
      authorName: 'kinoman104',
      authorAvatar: 'assets/images/avatars/kinoman104.png',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: 'Eat super healthy for 1 month',
      authorName: 'kinoman104',
      authorAvatar: 'assets/images/avatars/kinoman104.png',
      goalDirection: GoalDirection.HEALTH,
    },
    {
      goal: 'Vacation with my family',
      authorName: 'kinoman104',
      authorAvatar: 'assets/images/avatars/kinoman104.png',
      goalDirection: GoalDirection.RELATIONSHIP,
    },
    {
      goal: 'Find a new job in IT',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: 'Get a real estate license',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: `Walk 12K steps a day in ${this.getCurrentMonth()}`,
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: 'Go to the gym 4 days a week',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.HEALTH,
    },
    {
      goal: 'Go on a cruise with my family this Christmas',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.RELATIONSHIP,
    },
    {
      goal: 'Visit 5 national parks in California',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.JOY,
    },
    {
      goal: 'Make an extra $400 per month on eBay',
      authorName: 'Alex',
      authorAvatar: 'assets/images/avatars/alex-1.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
    {
      goal: 'Clean up my garage',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.OTHER,
    },
    {
      goal: 'Go to Hawaii this Summer',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.JOY,
    },
    {
      goal: 'Learn mountain skiing',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.JOY,
    },
    {
      goal: 'Surprise my girlfriend with a romantic date',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.RELATIONSHIP,
    },
    {
      goal: 'Buy a new car for my mom',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.RELATIONSHIP,
    },
    {
      goal: 'Read all “The Lord of the Rings"',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.JOY,
    },
    {
      goal: 'Create 5 projects in Python for my portfolio',
      authorName: 'Neil Cliff',
      authorAvatar: 'assets/images/avatars/alex-2.jpg',
      goalDirection: GoalDirection.FINANCE,
    },
  ];

  posts = this.getPosts();

  private getCurrentMonth() {
    const monthMap: Record<number, string> = {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };
    const curMont = new Date().getMonth();
    return monthMap[curMont];
  }

  private getPosts() {
    return this._posts.sort(() => Math.random() - 0.5);
  }
}
