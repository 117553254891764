import { DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { environment } from '@goalmate/environment';

@Component({
    selector: 'goalmate-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
})
export class NavbarComponent {
  document = inject(DOCUMENT);

  onLogin() {
    const window = this.document.defaultView;
    if (!window?.location) return;
    window.location.href = environment.host.app;
  }

  onSignup() {
    const window = this.document.defaultView;
    if (!window?.location) return;
    window.location.href = environment.host.app + '/auth/signup';
  }
}
