<div class="container flex items-center justify-between h-20 px-3 mx-auto">
  <div class="flex items-center gap-2">
    <img src="assets/images/logo-icon.png" width="40" height="40" alt="Logo" />
    <span class="text-3xl font-medium align-center">GoalMate</span>
  </div>

  <div class="flex items-center gap-6">
    <button
      (click)="onSignup()"
      class="text-lg font-medium transition-colors duration-200 text-neutral hover:text-accent"
    >
      Sign up
    </button>
    <button
      (click)="onLogin()"
      class="btn btn-neutral font-light w-[100px] btn-sm"
    >
      Login
    </button>
  </div>
</div>
