import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { BodyFeatureBalanceComponent } from './components/body-feature-balance/body-feature-balance.component';
import { BodyFeatureTrackerComponent } from './components/body-feature-tracker/body-feature-tracker.component';
import { BodyFeatureAiComponent } from './components/body-feature-ai/body-feature-ai.component';
import { MainFeaturesComponent } from './components/main-features/main-features.component';
import { FeaturedOnComponent } from './components/featured-on/featured-on.component';
import { SecondSectionComponent } from './components/second-section/second-section.component';
import { FirstScreenComponent } from './components/first-screen/first-screen.component';
import { NavbarComponent } from './components/navbar/navbar.component';

@Component({
    selector: 'goalmate-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NavbarComponent,
        FirstScreenComponent,
        SecondSectionComponent,
        FeaturedOnComponent,
        MainFeaturesComponent,
        BodyFeatureAiComponent,
        BodyFeatureTrackerComponent,
        BodyFeatureBalanceComponent,
        WaitListComponent,
        FooterComponent,
    ],
})
export class AppComponent {}
