import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { GoalToImgService } from '@goalmate/services';
import { GoalDirection } from '@goalmate/typings';

export interface CommunityPost {
  goal: string;
  authorName: string;
  authorAvatar: string;
  goalDirection: GoalDirection;
}

@Component({
  selector: 'goalmate-community-card',
  standalone: true,
  imports: [],
  templateUrl: './community-card.component.html',
  styleUrl: './community-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityCardComponent implements OnInit {
  private goalToImg = inject(GoalToImgService);

  post = input.required<CommunityPost>();

  img = signal<string | undefined>(undefined);

  ngOnInit() {
    this.setPostImg();
  }

  private async setPostImg() {
    const goal = {
      direction: this.post().goalDirection,
      goal: this.post().goal,
    };
    const img = await this.goalToImg.makeImg(goal);
    this.img.set(img);
  }
}
