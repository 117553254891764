import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMsg',
  standalone: true,
})
export class ErrorMsgPipe implements PipeTransform {
  transform(erorCode: string): string {
    switch (erorCode) {
      case 'auth/user-not-found':
        return 'User not found';
      case 'auth/email-already-exists':
        return 'Email already exists';
      case 'auth/id-token-expired':
        return 'Token expired';
      case 'auth/id-token-revoked':
        return 'Token revoked';
      case 'auth/insufficient-permission':
        return 'Insufficient permission';
      case 'auth/internal-error':
        return 'Internal error';
      case 'auth/invalid-credential':
        return 'Invalid credential';
      case 'auth/invalid-email':
        return 'Invalid email';
      case 'auth/invalid-password':
        return 'Invalid password';
      case 'auth/invalid-phone-number':
        return 'Invalid phone number';
      case 'auth/invalid-provider-data':
        return 'Invalid provider data';
      case 'auth/maximum-user-count-exceeded':
        return 'Maximum user count exceeded';
      case 'auth/operation-not-allowed':
        return 'Operation not allowed';
      case 'auth/phone-number-already-exists':
        return 'Phone number already exists';
      case 'auth/project-not-found':
        return 'Project not found';
      case 'auth/reserved-claims':
        return 'Reserved claims';
      case 'auth/session-cookie-expired':
        return 'Session cookie expired';
      case 'auth/uid-already-exists':
        return 'Uid already exists';
      case 'auth/unauthorized-continue-uri':
        return 'Unauthorized continue uri';
      case 'auth/wrong-password':
        return 'Wrong password';
      case 'auth/user-disabled':
        return 'User disabled';
      case 'auth/too-many-requests':
        return 'Too many requests';
      case 'auth/email-already-in-use':
        return 'Email already in use';
      default:
        return erorCode;
    }
  }
}
