import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FilterContentItem, FilterSection } from '../filter.interface';
import { FilterItemComponent } from '../filter-item/filter-item.component';
import { FilterSectionComponent } from '../filter-section/filter-section.component';
import { dialog } from '../../dialog/dialog-animation';

@Component({
  selector: 'goalmate-filter',
  standalone: true,
  imports: [FilterSectionComponent, FilterItemComponent],
  template: ` <div
    class="card rounded-xl shadow-xl bg-base-100"
    [@dialogAnimation]
  >
    <div class="card-body">
      <h2 class="card-title mx-auto text-2xl">Filter</h2>
      @for(section of filters(); track $index) {
      <goalmate-filter-section
        [title]="section.title"
        [key]="section.key"
        [bottomBorder]="!$last"
      >
        <!-- Filter items -->
        @for(filter of section.filters; track $index) {
        <goalmate-filter-item
          [filter]="filter"
          (filterChange)="updateFilter(section.key, $index, $event)"
        />
        }
      </goalmate-filter-section>
      }
      <div class="card-actions flex justify-end gap-4 mt-4">
        <button class="btn btn-outline" (click)="onCancel()">Cancel</button>
        <button
          class="btn btn-primary"
          [disabled]="!validFilter()"
          (click)="onConfirm()"
        >
          Show results
        </button>
      </div>
    </div>
  </div>`,
  styles: `:host {
    @apply mx-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class FilterComponent {
  private dialogRef = inject(DialogRef<FilterSection[], FilterComponent>);
  private _filters = inject<FilterSection[]>(DIALOG_DATA);

  filters = signal<FilterSection[]>(this._filters);

  validFilter = signal<boolean>(true);

  updateFilter(key: string, index: number, filter: FilterContentItem) {
    // Update filters
    this.filters.update((filters) => {
      const sectionIndex = filters.findIndex((s) => s.key === key);
      if (sectionIndex === -1) {
        return filters;
      }
      filters[sectionIndex].filters[index] = filter;
      return filters;
    });
    // Update valid filter
    this.validFilter.set(this.validateFilter(this.filters()));
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.filters());
  }

  private validateFilter(filters: FilterSection[]) {
    return filters.every((s) => s.filters.some((f) => f.selected));
  }
}
