import { DatePipe, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { PostCommunity } from '@goalmate/typings';
import { PostCommentsComponent } from '../post-comments/post-comments.component';

@Component({
  selector: 'goalmate-post-card',
  standalone: true,
  imports: [DatePipe, NgOptimizedImage, NgTemplateOutlet],
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCardComponent {
  private dialog = inject(Dialog);

  like = output<PostCommunity>();
  unlike = output<PostCommunity>();

  loadComments = output<PostCommunity>();
  newComment = output<{ post: PostCommunity; comment: string }>();

  post = input.required<PostCommunity>();

  onLike() {
    const post = this.post();
    this.like.emit(post);
  }

  onUnlike() {
    const post = this.post();
    this.unlike.emit(post);
  }

  onOpenComments() {
    if (this.post().commentsCount && !this.post().commentsLoaded) {
      this.loadComments.emit(this.post());
    }
    const dialogRef = this.dialog.open(PostCommentsComponent, {
      data: { post: this.post },
      height: '90svh',
      width: '90vw',
      maxWidth: '1100px',
      maxHeight: '900px',
      autoFocus: '__none-existing-class__',
    });

    dialogRef.componentInstance?.newComment.subscribe((comment) => {
      this.newComment.emit({ post: this.post(), comment });
    });
  }
}
