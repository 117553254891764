import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'goalmate-loader',
  standalone: true,
  imports: [],
  template: ` <span class="loading loading-dots loading-sm"></span> `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {}
