import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

const selector = 'a[goalmate-menu-item], [goalmate-menu-item]';

@Directive({
  selector,
  standalone: true,
})
export class GoalmateMenuItemDirective {
  @Output() clicked = new EventEmitter<void>();

  @HostBinding('class') class =
    'hover:bg-base-200 cursor-pointer px-4 py-2 w-full truncate text-sm transition-all block outline-none';

  @HostListener('click') onClick() {
    this.clicked.emit();
  }
}
