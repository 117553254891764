import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { dialog } from '../dialog-animation';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  GoalToImgService,
  ModerationService,
  PostsService,
} from '@goalmate/services';
import { Goal, Post } from '@goalmate/typings';
import { finalize } from 'rxjs';

export interface CompleteGoalDialogResponse {
  result: boolean;
  postContent: string;
  post?: Post;
}

export interface CompleteGoalDialogData {
  goal: Goal;
}

@Component({
  selector: 'goalmate-complete-goal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  template: `<div
    class="card rounded-xl shadow-xl bg-base-100"
    @dialogAnimation
  >
    <div class="card-body">
      <h2 class="card-title">Complete Goal</h2>
      <p>Are you sure you want to complete this goal?</p>
      <p>
        Goal will be marked as completed. You can always reactivate it later.
      </p>

      <!-- Post content -->
      <label class="form-control">
        <div class="label">
          <span class="label-text text-base font-medium">Goal capture</span>
        </div>
        <textarea
          [class.textarea-error]="postContent.errors"
          [formControl]="postContent"
          class="textarea textarea-bordered"
          placeholder="Goal capture"
          (ngModelChange)="inapropriateContent.set(false)"
        ></textarea>
        <div class="label">
          @if(postContent.errors) {
          <span class="label-text-alt text-error"
            >Goal capture is required</span
          >
          } @if(inapropriateContent()) {
          <label class="label">
            <span class="label-text-alt text-error"
              >Inappropriate capture detected. Please, set another goal
              capture</span
            >
          </label>
          }
        </div>
      </label>

      <!-- Toggle to publish -->
      <div class="form-control w-fit mt-1">
        <label class="label cursor-pointer">
          <span class="label-text mr-4 text-base font-medium"
            >Publish goal</span
          >
          <input type="checkbox" class="toggle" [(ngModel)]="publishPost" />
        </label>
      </div>

      <div class="card-actions flex justify-end gap-4 mt-4">
        <button
          class="btn btn-outline"
          (click)="onCancel()"
          [disabled]="isLoading()"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary"
          (click)="onConfirm()"
          [disabled]="postContent.errors || isLoading()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>`,
  styles: `:host {
    @apply mx-4 block;
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class CompleteGoalComponent {
  private fb = inject(FormBuilder);
  private dialorRef = inject(DialogRef);
  private goalToImg = inject(GoalToImgService);
  private postsService = inject(PostsService);
  private moderationService = inject(ModerationService);

  inapropriateContent = signal(false);
  isLoading = signal(false);

  protected data = inject<CompleteGoalDialogData>(DIALOG_DATA);

  postContent = this.fb.nonNullable.control<string>(
    this.postsService.makeContent(this.data.goal),
    Validators.required,
  );

  publishPost = false;

  onCancel() {
    this.dialorRef.close({ result: false });
  }

  async onConfirm() {
    const postContent = this.postContent.value;

    this.isLoading.set(true);
    this.moderationService
      .moderate(postContent as string)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe(async (passed) => {
        // Validate content
        if (!passed) {
          this.inapropriateContent.set(true);
          return;
        }
        // Complete goal
        if (this.publishPost) {
          try {
            const post = await this.createPost();
            this.dialorRef.close({ result: true, post, postContent });
          } catch (error) {
            console.error(error);
          }
        } else {
          this.dialorRef.close({ result: true, postContent });
        }
      });
  }

  private async createPost() {
    const content = this.postContent.value;
    const goalImage = await this.goalToImg.makeImg(this.data.goal);
    const goalImageBase64 = goalImage.split(',')[1];
    const userId = this.data.goal.userId;
    const post = await this.postsService.createPost(
      userId,
      goalImageBase64,
      content,
    );
    return post;
  }
}
