<goalmate-navbar />
<goalmate-first-screen />
<goalmate-main-features />
<goalmate-descriptions />
<goalmate-program />
<goalmate-mentors />
<goalmate-community />
<goalmate-footer />

<!-- TODO: remove these components -->
<!-- <goalmate-wait-list></goalmate-wait-list> -->
<!-- <goalmate-second-section></goalmate-second-section> -->
<!-- <goalmate-featured-on></goalmate-featured-on> -->
