<!-- Avatar -->
<div class="self-start avatar">
  <div class="w-8 rounded-full">
    <img [src]="comment().user.avatar" width="32" height="32" />
  </div>
</div>
<!-- Comment -->
<div>
  <p class="whitespace-pre-line">
    <strong>{{ comment().user.name }}</strong> {{ comment().content }}
  </p>
  <!-- Comment date -->
  <p class="text-xs opacity-50">
    {{ comment().createdAt | relativeTime }}
  </p>
</div>
