<h2 class="mb-16 text-4xl text-center">Meet Your Mentors</h2>

<!-- Mentor name buttons  -->
<div class="flex justify-center gap-4 mb-8">
  @for(mentor of mentors; track mentor.id) {
  <ng-container
    *ngTemplateOutlet="
      mentorButton;
      context: {
        $implicit: mentor,
        isSelected: selectedMentorId() === mentor.id
      }
    "
  ></ng-container>
  }
</div>

<!-- Swiper slider -->
<div class="relative">
  <swiper-container #swiperRef goalmateSwiper [config]="swiperOpt" init="false">
    <swiper-slide>
      <ng-container
        *ngTemplateOutlet="mentor; context: { $implicit: mentors[0] }"
      ></ng-container>
    </swiper-slide>
    <swiper-slide>
      <ng-container
        *ngTemplateOutlet="mentor; context: { $implicit: mentors[1] }"
      ></ng-container>
    </swiper-slide>
    <swiper-slide>
      <ng-container
        *ngTemplateOutlet="mentor; context: { $implicit: mentors[2] }"
      ></ng-container>
    </swiper-slide>
  </swiper-container>
</div>

<!-- Slide templates -->
<ng-template #mentor let-mentor>
  <!-- Columns -->
  <div class="flex flex-wrap gap-6 px-4">
    <!-- Column 1-->
    <!-- Picture -->
    <ng-container
      *ngTemplateOutlet="mentorPicture; context: { $implicit: mentor }"
    ></ng-container>

    <!-- Columnn 2 -->
    <!-- Name and Skills -->
    <div class="flex flex-col gap-4 grow">
      <h3 class="text-2xl font-medium">{{ mentor.fullName }}</h3>
      <goalmate-mentors-skills [skills]="mentor.skills" />
    </div>

    <!-- Bio -->
    <ng-container
      *ngTemplateOutlet="mentorBio; context: { $implicit: mentor }"
    ></ng-container>
  </div>
</ng-template>

<!-- Mentor picture -->
<ng-template #mentorPicture let-mentor>
  <div
    class="p-3 bg-white border rounded-sm shadow-xl w-[300px] h-[300px] md:h-[350px]"
  >
    <div class="w-full h-full overflow-hidden">
      <img
        [src]="mentor.pinctureUrl"
        [alt]="mentor.fullName"
        loading="lazy"
        class="w-full h-auto -mt-20 md:-mt-16"
      />
    </div>
  </div>
</ng-template>

<!-- Mentor bio -->
<ng-template #mentorBio let-mentor>
  <div class="mb-6 shadow-xl card card-bordered">
    <div class="card-body">
      <h3 class="mb-3 text-2xl font-medium text-center">Biography</h3>

      <!-- Player buttosn -->
      @if(isPlaying()) {
      <button (click)="onStop()" class="flex gap-1 mb-1 w-fit">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
          />
        </svg>
        Stop
      </button>
      } @else {
      <button
        (click)="onPlay(mentor.bioAudioUrl)"
        class="flex gap-1 mb-1 w-fit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
          />
        </svg>
        Play
      </button>
      }

      <!-- Bio -->
      <div
        [innerHTML]="mentor.shortBioHtml"
        class="flex flex-col gap-4 text-lg"
      ></div>
      <button
        class="self-start font-medium underline text-info"
        (click)="onReadFullBio(mentor.bio, mentor.fullName, mentor.bioAudioUrl)"
      >
        Read more
      </button>
    </div>
  </div>
</ng-template>

<!-- Button template -->
<ng-template #mentorButton let-mentor let-slected="isSelected">
  <button
    class="text-lg font-medium"
    [class.underline]="slected"
    (click)="changeSlide(mentor.id)"
  >
    {{ mentor.shortName }}
  </button>
</ng-template>
