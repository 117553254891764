import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@goalmate/environment';
import { map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModerationService {
  private http = inject(HttpClient);

  private blockedContentCache = new Set<string>();

  /**
   * Moderates the given content by checking if it is blocked.
   * If the content is found in the blocked content cache, it immediately returns an observable of `false`.
   * Otherwise, it sends a POST request to the moderation API to check if the content passes moderation.
   * If the content does not pass moderation, it is added to the blocked content cache.
   *
   * @param {string} content - The content to be moderated.
   * @returns {Observable<boolean>} - An observable that emits `true` if the content passes moderation, otherwise `false`.
   */
  moderate(content: string) {
    if (this.blockedContentCache.has(content)) {
      return of(false);
    }
    return this.http
      .post<{ passed: boolean }>(environment.api.moderateContent, {
        content,
      })
      .pipe(
        map((res) => res.passed),
        tap((passed) => {
          if (!passed) {
            this.blockedContentCache.add(content);
          }
        }),
      );
  }
}
