<div class="container grid grid-cols-2 mx-auto gap-y-6">
  <!-- Column Left -->
  <div
    class="col-span-2 lg:col-span-1"
    [class.order-last]="reverse && !isMobile()"
  >
    <!-- Title -->
    <h2 class="my-4 text-4xl font-bold text-base-content">
      <ng-content select="[feature-title]"></ng-content>
    </h2>

    <!-- Description -->
    <div class="my-4 text-base-content">
      <ng-content select="[feature-description]"></ng-content>
    </div>
  </div>

  <!-- Columt Right -->
  <div
    class="flex items-center justify-center col-span-2 lg:col-span-1 text-base-content"
  >
    <ng-content select="[feature-image]"></ng-content>
  </div>
</div>
