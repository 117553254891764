<!-- Image -->
<div class="max-w-[350px] h-full w-full">
  @if(img(); as img) {
  <img
    [src]="img"
    height="350"
    width="auto"
    alt="Goal"
    [attr.download]="post().authorName + '.png'"
  />
  } @else {
  <div
    class="flex items-center justify-center w-full bg-primary h-[300px] opacity-90"
  >
    <span class="loading loading-spinner loading-lg"></span>
  </div>
  }

  <!-- Content -->
  <div class="p-4 card-body">
    <div class="flex gap-3">
      <!-- Avatar -->
      <div class="avatar">
        <div class="w-6 rounded-full">
          <img [src]="post().authorAvatar" />
        </div>
      </div>
      <!-- Name -->
      <p class="font-medium">{{ post().authorName }}</p>
    </div>
  </div>
</div>
