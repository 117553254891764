import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RelativeTimePipe } from '@goalmate/pipes';
import { PostComment } from '@goalmate/typings';

@Component({
  selector: 'goalmate-post-comment',
  standalone: true,
  imports: [RelativeTimePipe],
  templateUrl: './post-comment.component.html',
  styleUrl: './post-comment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentComponent {
  comment = input.required<PostComment>();
}
