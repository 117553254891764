import { animate, style, transition, trigger } from '@angular/animations';

export const dialog = trigger('dialogAnimation', [
  transition('void => *', [
    style({
      opacity: 0,
      visibility: 'hidden',
      transform: 'scale(0.93) translateY(-4px)',
    }),
    animate(
      '200ms ease-in-out',
      style({
        opacity: 1,
        visibility: 'visible',
        transform: 'scale(1) translateY(0)',
      }),
    ),
  ]),
  transition('* => void', [
    animate(
      '200ms ease-in-out',
      style({
        opacity: 0,
        visibility: 'hidden',
        transform: 'scale(0.93) translateY(-4px)',
      }),
    ),
  ]),
]);
