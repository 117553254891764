import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MentorsService } from '@goalmate/services';
import { dialog } from '../dialog-animation';
import { NgClass } from '@angular/common';

export interface ChangeMentorResponse {
  mentorId?: string;
}

@Component({
  selector: 'goalmate-change-mentor',
  standalone: true,
  imports: [NgClass],
  template: ` <div
    class="shadow-xl card rounded-xl bg-base-100"
    @dialogAnimation
  >
    <div class="card-body">
      <h2 class="mx-auto mb-6 text-center card-title">Change mentor</h2>
      <ul class="flex flex-col w-full gap-2 mb-6 rounded-box">
        @for (mentor of mentorsList; track mentor.id) {
        <li
          class="flex items-center p-4 transition-all border-2 cursor-pointer rounded-xl"
          tabindex="0"
          (click)="selectedMentor = mentor.id"
          [ngClass]="{
            '!border-primary': mentor.id === selectedMentor
          }"
        >
          <div class="avatar">
            <div class="w-12 mr-4 rounded-full sm:w-16">
              <img [src]="mentor.avatarUrl" />
            </div>
          </div>
          <h3 class="text-lg">
            {{ mentor.name }}
          </h3>
        </li>
        }
      </ul>

      <div class="flex justify-end gap-4 mt-4 card-actions">
        <button class="btn btn-outline" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary" (click)="onConfirm()">Confirm</button>
      </div>
    </div>
  </div>`,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialog],
})
export class ChangeMentorComponent {
  private dialorRef = inject(DialogRef);
  private mentors = inject(MentorsService);
  private data = inject(DIALOG_DATA);

  mentorsList = this.mentors.getMentors();
  selectedMentor = this.data.selectedMentorId;

  /**
   * Closes the dialog and returns a result of false.
   */
  onCancel() {
    this.dialorRef.close();
  }

  /**
   * Closes the dialog and returns a result of true,
   * along with the selected timezone and next check date.
   */
  onConfirm() {
    this.dialorRef.close({
      mentorId: this.selectedMentor,
    });
  }
}
